import React from 'react'
import { useNavigate } from "react-router-dom";

import MainLayout from '../../components/MainLayout/main-layout.js';
import SidebarButton from '../../components/Sidebar/sidebar-button.js';

import './style.css'

const LegalTemplate = (props) => {
    const navigate = useNavigate();

    return (
        <MainLayout legalSelected={true}
                    subMenuContent={() => (
                <div className="legal-nav">
                    <SidebarButton action={() => {
                        navigate('/terms')
                    }}
                        navTitle="Terms &amp; Conditions"
                        isSelected={props.termsSelected ?? false} />
                    <SidebarButton action={() => {
                        navigate('/privacy')
                    }}
                        navTitle="Privacy"
                        isSelected={props.privacySelected ?? false} />
                    <SidebarButton action={() => {
                        navigate('/cookies')
                    }}
                        navTitle="Cookies"
                        isSelected={props.cookiesSelected ?? false} />
                </div>)}>
            {
                <div className="legal-container1">
                    <div className="legal-container2">
                        <span className="legal-body-text-container">
                            <h1 className="legal-body-title">
                                {props.legalText.title}
                            </h1>
                            <span className="legal-body">
                                {props.legalText.body}
                            </span>
                        </span>
                    </div>
                </div>
            }
        </MainLayout>
    )
}

export default LegalTemplate
