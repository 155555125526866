import React, { useState } from 'react';
import { reactToChat, removeChatReaction } from '../../data/chat-data-access';
import ThumbDownIcon from '../Icons/thumb-down';
import ThumbUpIcon from '../Icons/thumb-up';

import './style.css'

const LIKE = 0;
const DISLIKE = 1;

const ReactionToolbar = (props) => {
    const react = async (reaction) => {
        if (reaction === props.chat.UserReaction) {
            await removeChatReaction(props.chat.Id, props.account.Id);
            await props.refresh();
            return;
        }

        if(reaction === LIKE)
            props.likeAction && props.likeAction();
        else if (reaction === DISLIKE)
            props.dislikeAction && props.dislikeAction();

        await reactToChat(props.chat.Id, props.account.Id, reaction);
        await props.refresh();
    }

    return (
        <div className="chat-reaction-button-container">
            <button className="button no-border-button chat-reaction-button">
                <ThumbUpIcon onClick={() => react(LIKE)} />
                <span>{props.chat.Likes}</span>
            </button>
            <button className="button no-border-button chat-reaction-button">
                <ThumbDownIcon onClick={() => react(DISLIKE)} />
                <span>{props.chat.Dislikes}</span>
            </button>
        </div>
    )
}

export default ReactionToolbar;