import React from 'react'
import './style.css';

const AddChatIcon = (props) => {
    const className = props.className ?? "small";

    return (
        <svg viewBox="0 0 1024 1024"
             className={className}
             onClick={() => props.onClick && props.onClick()}>
            <path d="M726 470v-86h-172v-170h-84v170h-172v86h172v170h84v-170h172zM938 170v768l-170-170h-598q-34 0-59-26t-25-60v-512q0-34 25-59t59-25h684q34 0 59 25t25 59z"></path>
        </svg>
    )
}

export default AddChatIcon