import React from 'react'
import LegalTemplate from "./legal-template";

const Cookies = (props) => {
    const cookieStatement = {
        title: "Cookie Usage",
        body: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              bibendum orci in sem s tempus.Pellentesque a eleifend
              purus.Duis euismod suscipit neque, vitae maximus diam ornare
              vitae.Sed laoreet dolor mauris, et condimentum dui dictum id.Ut
              vitae erat tristique, bibendum dui eget, sodales tortor.
              Pellentesque dapibus, orci quis bibendum egestas, diam ipsum
              dapibus erat, at varius justo ligula eget leo.Lorem ipsum dolor
              sit amet, consectetur adipiscing elit. Etiam eget commodo justo.`
    };

    return (
        <LegalTemplate legalText={cookieStatement}
                       cookiesSelected={true} >
        </LegalTemplate>
    )
}

export default Cookies;