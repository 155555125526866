import React, { useEffect, useState } from 'react';
import Base64Image from '../../components/Utility/base-64-image.js';
import { useNavigate } from "react-router-dom";
import HorizontalBarInlineText from '../Controls/horizontal-bar-inline-text.js';

import './style.css'

const MemberAdded = (props) => {
    const navigate = useNavigate();

    var channelDescriptor = props.properties?.find((value) => value.Key === "channel-descriptor")?.Value;
    var tripOrChat = channelDescriptor ?? "channel"

    return (
        <div className="member-added-message">
            <HorizontalBarInlineText text={props.chat.SentByAccount.DisplayName + " joined the " + tripOrChat + "!"}/>
        </div>
    )
}

export default MemberAdded