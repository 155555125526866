import React, { useState } from 'react';
import './style.css'

const ItineraryEvent = ({ event }) => {
    return (
        <span>
            <strong>{event.title}</strong>
            {event.desc && ':  ' + event.desc}
        </span>
    )
}

export default ItineraryEvent;
