
import React, { useEffect, useState } from 'react';

import ArrowIcon from '../Icons/arrow.js';
import SidebarCategory from '../Sidebar/sidebar-category.js';
import SidebarItem from '../Sidebar/sidebar-item.js';
import './style.css'

const Channel = (props) => {
    var channelItemStyle = props.channel.LastMessage.Read
        ? "unread"
        : "read";

    const isSelected = props.selectedChannel?.Id === props.channel.Id;

    var lastMessageContent = props.channel.LastMessage;
    const embeddedLink = props.channel.LastMessage?.match(/(?:\(\"(.+)\"\)\[\"((?:https|http)\:\/\/.*\..*)\"\])/) ?? [];

    if (embeddedLink && embeddedLink.length > 0)
        lastMessageContent = props.channel.LastMessage.replace(embeddedLink[0], embeddedLink[1]);

    var embeddedJson = props.channel.LastMessage?.match(/^(?:\["type:json"])/) ?? [];
    if (embeddedJson.length > 0) {
        var json = props.channel.LastMessage?.substring(embeddedJson[0].length)
        var object = JSON.parse(json);

        if (object.Type === "trip-suggestion")
            lastMessageContent = "A new trip was suggested"
        else if (object.Type === "trip-event-suggestion")
            lastMessageContent = "A new event was suggested"
        else if (object.Type === "user-joined-channel")
            lastMessageContent = "A new user joined the channel"
    }

    if (lastMessageContent?.length > 50)
        lastMessageContent = lastMessageContent.slice(0, 50) + "...";

    const navTitle = <div className="channel-item-name-content">
        <h1 className="chat-group-focused-text1">
            {props.channel.ChannelName}
        </h1>
        <h3 className="itinerary-list-item-group-name">
            {lastMessageContent}
        </h3>
    </div>;

    if (props.channel?.TripChannels) {
        return (
            <SidebarCategory navTitle={
                <h1 className="chat-group-focused-text1">
                    {props.channel.GroupName}
                </h1>
            }>
                <SidebarItem onClick={() => props.setSelectedChannel(props.channel)}
                    selected={isSelected}
                    navTitle={navTitle} />

                {
                    props.channel.TripChannels.map((channel, index) =>
                        <Channel key={index} channel={channel} selectedChannel={props.selectedChannel} setSelectedChannel={props.setSelectedChannel} />
                    )
                }
            </SidebarCategory>
        )
    }

    return (

        <SidebarItem onClick={() => props.setSelectedChannel(props.channel)}
                    selected={isSelected}
                    navTitle={navTitle} />

    )
}

export default Channel