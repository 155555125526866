import React from 'react';
import './horizontal-bar-inline-text.css'

const HorizontalBarInlineText = (props) => {

    return (
        <span className="horizontal-bar-inline-text">{props.text}</span>
    )
}

export default HorizontalBarInlineText;
