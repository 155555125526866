import React, { useEffect, useState, useRef } from 'react';
import Base64Image, { getBase64 } from '../../components/Utility/base-64-image.js';
import { useNavigate } from "react-router-dom";
import moment from 'moment'

import './style.css'
import ThumbUpIcon from '../Icons/thumb-up';
import ThumbDownIcon from '../Icons/thumb-down';
import Popup from '../Popup/popup.js';
import PageControl from '../Controls/page-control.js';
import ReactionToolbar from './reaction-toolbar.js';

const EventSuggestion = (props) => {
    const navigate = useNavigate();
    const [suggestAlternativeEventPopupOpen, setSuggestAlternativeEventPopupOpen] = useState(false);

    var eventName = props.properties?.find((value) => value.Key === "itinerary-item-name")?.Value;
    var eventDescription = props.properties?.find((value) => value.Key === "itinerary-item-description")?.Value;
    var itineraryId = props.properties?.find((value) => value.Key === "itinerary-id")?.Value;
    var itemId = props.properties?.find((value) => value.Key === "itinerary-item-id")?.Value;

    const goToItineraryEvent = () => {
        navigate(`/Itineraries?itinerary=${itineraryId}`)
    }

    //we should have a default image here
    //idk do we want to have a vote counter here? Or something?
    //probably need to only show the thumbs when the event is eligible to be voted on
    //  I don't like these ideas because the thumbs make it seem like you could like every idea, when in reality we are only voting on one idea
    /*
        regardless of which option we choose, the alternative suggested event should be created as a new itinerary item
            - we want to do this because we think it will make it easier to show on the calendar and deal with overlapping time slots
            - it begs the question, should we just replace the voting option object with an itinerary item entirely?
                - then there would be a link table to itinerary item votes
                - this would probably be a lot simpler
            - when a vote is completed, we will remove the losing options
                - users can reference those suggestions via the vote page though, so might be good to keep that
        Options:
            - option A: we rework the votes system to operate off of the most liked itinerary item
                - we can keep the vote results page and show the ratios of likes to dislikes there
                - the winner has the most likes
                    - would dislikes decrement the count of likes for the tabulation?
                - this would maybe shoehorn us into itinerary items being the only things people can vote on
                    - people might also want to be able to vote on an overall trip

            - option B: we let people like/dislike any message, except on dislikes of event suggestions we prompt them to create a new event suggestion
                - we keep the vote structure as it is and notify users in the group that an active vote has started
                - every new event suggestion resets the vote count?
                - likes and dislikes are arbitrary and have no weight on the overall vote
                - so far I like this the best because it is the least amount of change
                - i feel like there might be discontinuity in the user experience
                    - i.e. "everyone liked this option, why do we have to go vote on it?"

            - option C: we replace the like/dislike thing with something that just plainly allows a user to suggest an alternative
                - everything else stays the same as option B
                - clears up option B discontinuity issue

            - option D: if two things get liked show, there are two things on the itinerary
    */

    //When someone likes this it should "RSVP", we dont have a system for that yet but it should
    return (
        <div className={"chat-group-focused-message-" + props.chatStyle}>

            <Popup trigger={suggestAlternativeEventPopupOpen}
                setTrigger={setSuggestAlternativeEventPopupOpen}
                className="itineraries-list-add"
                contentClassName="add-new-itinerary-popup-content"
                showCloseButton={true}>
                <SuggestAlternativeEventPopupContent closePopup={() => setSuggestAlternativeEventPopupOpen(false)}
                                                     itineraryId={itineraryId}
                                                     itemId={itemId}
                                                     accountId={props.account.Id} />
            </Popup>

            <div onClick={() => goToItineraryEvent()}>
                <div className="link-preview">
                    <span>{props.chat.SentByAccount.DisplayName} suggested a new event</span>
                    {
                        props.chat?.Images && props.chat?.Images.length > 0 &&
                        <Base64Image
                            alt={""}
                            image={props.chat?.Images[0]?.Content}
                            contentType={props.chat?.Images[0]?.ContentType}
                            className="link-preview-image"
                        />
                    }
                    <span>{eventName}</span>

                    <span>{eventDescription}</span>
                </div>
            </div>
            <ReactionToolbar chat={props.chat} account={props.account} refresh={props.refresh} dislikeAction={() => setSuggestAlternativeEventPopupOpen(true)} />
        </div>
    )
}

const SuggestAlternativeEventPopupContent = ({closePopup, itineraryId, itemId, accountId}) => {
    const [newEventName, setNewEventName] = useState("");
    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DDTHH:mm"));
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DDTHH:mm"));
    const [eventDescription, setEventDescription] = useState("");

    const [imageInputKey, setImageInputKey] = useState(0);
    const [selectedImage, setSelectedImage] = useState()
    const fileInput = useRef(null);

    const handleFileUploadClick = event => {
        fileInput.current.click();
    };

    const handleFileSelection = async (file) => {
        let dataUrl = await getBase64(file);

        let imageData = dataUrl.replace('data:', '').replace(/^.+,/, '');
        let contentType = new RegExp("(?:image\/).*(?=\;)").exec(dataUrl)[0]
        var image = {
            FileName: crypto.randomUUID(),
            CreatedBy: accountId,
            Content: imageData,
            ContentType: contentType
        }

        setSelectedImage(image);
    }

    const handleInput = (e, setter) => {
        if (e.target.value === '\n') {
            return;
        }

        setter(e.target.value)
    }

    const pages = [
        {
            canContinue: newEventName.length > 0,
            backText: "No Thanks",
            backAction: closePopup,
            nextText: "Continue",
            content: <>
                <h3>Don't like this?</h3>
                <p>Suggest an alternative:</p>
                <span>Name your event:</span>
                <input type="text"
                    placeholder="Event Name..."
                    className="channel-member-input"
                    value={newEventName}
                    onChange={(e) => handleInput(e, setNewEventName)}
                />
            </>
        },
        {
            //need to have something here that allows people to skip this and just use the original dates
            //in order to do this we're either going to need to set the start end date on the message when an event is suggested or load the item in here.
            //we should probably do that anyways, would be nice to show that on the message
            canContinue: true,
            content: <>
                <p>Suggest an alternative:</p>
                <span>When will your event take place?</span>
                <label className="trip-add-event-label">
                    Start Date:
                </label>

                <input
                    type="datetime-local"
                    className="trip-add-event-input"
                    onChange={(event) => {
                        setStartDate(event.target.value)
                    }}
                    value={startDate}
                />


                <label className="trip-add-event-label">
                    End Date:
                </label>

                <input
                    type="datetime-local"
                    className="trip-add-event-input"
                    onChange={(event) => {
                        setEndDate(event.target.value)
                    }}
                    value={endDate}
                />
            </>
        },
        {
            canContinue: eventDescription.length > 0,
            content: <>
                <p>Suggest an alternative:</p>
                <span>Describe your vision:</span>

                <input type="text"
                    placeholder="Write your pitch here..."
                    className="channel-member-input"
                    value={eventDescription}
                    onChange={(e) => handleInput(e, setEventDescription)}
                />

                {
                    selectedImage &&
                    <Base64Image
                        alt={""}
                        image={selectedImage.Content}
                        contentType={selectedImage.ContentType}
                        className="chat-added-image"
                    />
                }

                <input key={imageInputKey}
                    type="file"
                    name="image"
                    ref={fileInput}
                    onChange={(event) => {
                        console.log(event.target.files[0]);
                        handleFileSelection(event.target.files[0]);
                    }}
                    style={{ display: 'none' }}
                />

                <button className="button" onClick={() => handleFileUploadClick()}>
                    {
                        selectedImage ? "Change Photo" : "Attach a photo"
                    }
                </button>

            </>
        }
    ];

    return (
        <div className="messages-panel-popup">
            <PageControl submitText="Create"
                pages={pages} />
        </div>
    )
}

export default EventSuggestion