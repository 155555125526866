import React, { useState } from 'react'

import ArrowIcon from '../Icons/arrow.js';

import './style.css';

const SidebarItem = (props) => {
    return (
        <div>
            <button className="sidebar-category-button-container"
                style={{ backgroundColor: props.selected ? "var(--dl-color-gray-700)" : "unset" }}
                onClick={() => {
                    if (props.onClick)
                        props.onClick();
                }}>

                {props.navTitle}

                <ArrowIcon className={`arrow-icon extra-small`} />
            </button>
        </div>
    )
}

export default SidebarItem