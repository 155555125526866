import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './style.css';

import LoginButton from "../Login/login-button";
import Popup from '../Popup/popup.js';
import AccountMenu from './account-menu.js';
import Base64Image from '../../components/Utility/base-64-image';

import { getAccount } from '../../data/account-data-access.js';

const AccountIcon = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const navigate = useNavigate();

    const [menuOpen, setMenuOpen] = useState(false);
    const [activeUser, setActiveUser] = useState();

    document.body.style.overflow = menuOpen ? "hidden" : "unset";

    useEffect(() => {
        const load = async () => {
            var user = await getUser();

            if (!(user?.IsAccountComplete ?? true))
                navigate("completeprofile")
        }

        load();
    }, [user]);

    const getUser = async () => {
        if (!isAuthenticated || isLoading)
            return;

        var res = await getAccount(user.email);

        setActiveUser(res);

        return res;
    }

    if (isLoading || !isAuthenticated) {
        return <LoginButton/>
    }

    return (
        isAuthenticated && (
            <div className="icon-container">
                <button className="account-icon-button" onClick={() => setMenuOpen(true)}>
                    {
                        activeUser?.ProfileImage
                            ? <Base64Image
                                alt={user.name}
                                image={activeUser?.ProfileImage.Content}
                                contentType={activeUser?.ProfileImage.ContentType}
                                className="account-icon"
                            />
                            : <img src={user.picture}
                                alt={user.name}
                                className="account-icon" />
                    }
                </button>
                <Popup trigger={menuOpen}
                        setTrigger={setMenuOpen}
                        className="account-menu-style"
                        contentClassName="account-menu-content-style"
                        showCloseButton={false}>
                    <AccountMenu user={user} account={activeUser}/>
                </Popup>
            </div>
        )
    )
};

export default AccountIcon;
