
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import NotificationPopupItem from '../../components/Notifications/notification-popup-item';
import { getAccountNotifications } from '../../data/notification-data-access';

const NotificationDashboardContent = (props) => {
    const [notifications, setNotifications] = useState(props.notifications)
    const [showUnreadOnly, setShowUnreadOnly] = useState(true);

    useEffect(() => {
        const load = async () => {
            var res = await reloadNotifications();
        }

        load();
    }, [showUnreadOnly]);

    //TODO We need to do a loading spinner while this is loading, I don't have one anywhere yet IIRC
    const loadNotifications = async (accountId, read) => {
        setNotifications(null);
        var res = await getAccountNotifications(accountId, read);
        setNotifications(res);

        return res;
    }

    const reloadNotifications = async () => {
        await loadNotifications(props.account.Id, showUnreadOnly ? false : null);
    }

    return (
        <div className="notification-dashboard-content-container">
            <div className="notification-popup-content-header">
                <h2>Notifications</h2>
            </div>
            <div className="notification-popup-content-body">
                {
                    (!notifications || notifications?.length === 0) &&
                    <span className="no-content-message">There are no notifications to show</span>
                }
                {
                    notifications && notifications?.length > 0 &&
                    notifications.map((c, idx) => <NotificationPopupItem key={idx}
                        account={props.account}
                        notification={c}
                        reloadNotifications={reloadNotifications} />)
                }
            </div>
        </div>
    )
}

export default NotificationDashboardContent;