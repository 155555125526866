
import React, { useEffect, useState } from 'react';
import Popup from '../Popup/popup';
import Base64Image from '../../components/Utility/base-64-image.js';

import PersonAddIcon from '../Icons/person-add';
import CloseIcon from '../Icons/close';
import PaperAirplaneIcon from '../Icons/paper-airplane';
import CheckIcon from '../Icons/check.js';

import { getChannelMembers, addChannelMember, removeChannelMember, deleteChannel } from '../../data/channel-data-access.js';
import { getAccount, searchAccount } from '../../data/account-data-access';
import { joinTrip } from '../../data/itinerary-data-access';
import './trip-member-control.css'

const TripMemberControl = (props) => {
    const [addMemberPopupOpen, setAddMemberPopupOpen] = useState(false);
    const [channelMembers, setChannelMembers] = useState([]);
    const [userIsInTrip, setUserIsInTrip] = useState(true);

    useEffect(() => {
        getChannelMembersList()
    }, [props.channel]);

    const getChannelMembersList = async () => {
        setUserIsInTrip(true);
        if (!props.channel)
            return;

        var res = await getChannelMembers(props.channel?.Id);

        if (res == null) {
            setUserIsInTrip(false);
            return;
        }

        setChannelMembers(res)

        var userInChannel = res.find((channelMember) => channelMember.Account.Id === props.account.Id);
        if (!userInChannel)
            setUserIsInTrip(false);
    }

    const _joinTrip = async () => {
        await joinTrip(props.account.Id, null, props.channel?.Id);
        props.setRefreshNeeded(true);
        await getChannelMembersList();
    }

    if (!userIsInTrip)
        return (
            <button className="button"
                style={{ marginRight: "20px" }}
                onClick={() => _joinTrip()}>
                Join Trip
            </button>
        )

    return (
        <>
            <Popup trigger={addMemberPopupOpen}
                setTrigger={setAddMemberPopupOpen}
                className="trip-member-control-popup"
                contentClassName="trip-member-control-popup-content"
                showCloseButton={true}>
                <AddMemberPopupContent account={props.user}
                    channel={props.channel}
                    refresh={() => props.setRefreshNeeded(true)}
                    closePopup={() => setAddMemberPopupOpen(false)}
                    channelMembers={channelMembers}
                    getChannelMembersList={() => getChannelMembersList()} />
            </Popup>
            <PersonAddIcon onClick={() => props.canOpen && setAddMemberPopupOpen(!addMemberPopupOpen)} />
        </>
    )
}

//TODO: do a loading spinner while this gets the members
const AddMemberPopupContent = (props) => {
    const [newChannelMember, setNewChannelMember] = useState("");
    const [userInputErrors, setUserInputErrors] = useState();
    const [showNoUserFound, setShowNoUserFound] = useState(false);

    const handleInput = e => {
        if (e.target.value === '\n') {
            return;
        }

        setUserInputErrors();
        setShowNoUserFound(false);
        setNewChannelMember(e.target.value);
    }

    const addOnEnter = e => {
        if (e.key === 'Enter') {
            addMember();
        }
    }

    const addMember = async () => {
        setUserInputErrors();
        setShowNoUserFound(false);

        var accountResult = await getAccount(newChannelMember, null);

        //see if they actually typed in a username
        if (!accountResult) {
            accountResult = await searchAccount(newChannelMember);

            let emailMatch = new RegExp(".+[@].+\..").exec(newChannelMember)
            console.log(emailMatch)
            console.log(accountResult)

            //no username found, but this is an email
            if (accountResult.length === 0 && emailMatch) {
                setShowNoUserFound(true);//ask if they want to invite them
                return;
            }
            else if (accountResult.length === 0) {
                setUserInputErrors("We couldn't find this user.");
                return;
            }

            accountResult = accountResult[0];
        }

        var newMemberResult = await addChannelMember(accountResult.Id, props.channel?.Id);
        if (!newMemberResult) {
            setUserInputErrors("Failed to add this user. Please try again.");
            return;
        }

        await props.getChannelMembersList();
        setNewChannelMember("");
    }

    const removeMemberFromChannel = async (member) => {
        if (!member)
            return;

        //If this is the last member in the channel, just delete it
        if (props.channelMembers.length === 1) {
            await removeChannelMember(member.Account.Id, props.channel?.Id);
            await deleteChannel(props.channel?.Id);
            props.refresh();
            props.closePopup();
        }

        await props.getChannelMembersList();
    }

    var channelMembersList = "";
    if (props.channelMembers.length > 0) {
        channelMembersList = props.channelMembers.map((member, index) =>
            <div key={index}
                className="trip-member-list-container">
                <div className="trip-member-list-item">
                    {
                        member.Account.ProfileImage
                            ? <Base64Image alt={""}
                                image={member.Account.ProfileImage.Content}
                                contentType={member.Account.ProfileImage.ContentType}
                                className="trip-member-list-profile-picture"
                            />
                            : <img alt="image"
                                src="https://play.teleporthq.io/static/svg/default-img.svg"
                                className="trip-member-list-profile-picture"
                            />
                    }
                    <div className="trip-member-list-profile-name-container">
                        <span className="trip-member-list-profile-display-name">{member.Account.DisplayName}</span>
                        <div>
                            <span className="trip-member-list-profile-second-name"> {member.Account.UserName != null && member.Account.UserName + "-"} {member.Account.Email} </span>
                        </div>
                    </div>
                </div>

                {
                    //Eventually we will make this work so that if you have a pending invite these buttons are active? Idk
                    member.Status === 0 &&
                    <button className="button member-status-container"
                        disabled={true}>
                        Pending
                    </button>
                }

                {
                    member.Status === 1 &&
                    <button className="button member-status-container"
                        disabled={true}>
                        Accepted
                    </button>
                }

                {
                    member.Status === 2 &&
                    <button className="button member-status-container"
                        disabled={true}>
                        Declined
                    </button>
                }
            </div>
        );
    }

    return (
        <div className="trip-member-list-popup-content">
            <label className="add-trip-member-popup-label">
                Add Members:
            </label>

            <div className="add-trip-member-input-container">
                <input type="text"
                    placeholder="Email or name..."
                    className="channel-member-input"
                    value={newChannelMember}
                    onChange={handleInput}
                    onKeyDown={addOnEnter}
                />

                <CheckIcon className="add-trip-member-check-icon extra-small-2" onClick={() => addMember()} />
            </div>

            {
                showNoUserFound &&
                <div className="add-trip-member-no-user-found-container">
                    <span className="add-trip-member-no-user-found-text"> We couldn't find that user. Would you like to invite them?
                        <button className="add-trip-member-no-user-found-invite-button button">
                            <span> Send Invite </span>
                            <PaperAirplaneIcon className="add-trip-member-send-invite-button-icon extra-small-2" />
                        </button>
                    </span>
                </div>
            }

            {
                userInputErrors &&
                <span className="add-trip-member-input-errors">
                    {userInputErrors}
                </span>
            }

            <br />
            <label>
                Current Members:
            </label>

            <div>
                {
                    channelMembersList
                }
            </div>

            <button className="button destructive-button"
                    onClick={() => removeMemberFromChannel(
                    {
                        Account: props.account
                    })}>
                { props.channel?.ItineraryId !== null ? "Leave Trip" : "Leave Chat" }
            </button>
        </div>
    )
}

export default TripMemberControl