import React, { useState, useEffect } from 'react';
import { getMutualFriends } from '../../data/friend-data-access';
import FriendList from './friend-list';

const MutualFriendList = ({ accountId, secondAccountId }) => {
    const [mutualFriends, setMutualFriends] = useState([]);

    useEffect(() => {
        const loadMutualFriends = async () => {
            await _getMutualFriends(accountId, secondAccountId);
        }

        loadMutualFriends();
    }, [accountId, secondAccountId]);

    const _getMutualFriends = async (accountId, secondAccountId) => {
        if (accountId === 0 || secondAccountId === 0)
            return;

        var res = await getMutualFriends(accountId, secondAccountId);

        if (res?.length > 0)
            setMutualFriends(res);
    }


    return (
        <div>
            <h2>Mutual Friends:</h2>
            <FriendList friends={mutualFriends}/>
        </div>
    )

}

export default MutualFriendList;