import React from 'react'

import MainLayout from '../../components/MainLayout/main-layout.js'
import NotFoundComponent from '../../components/NotFound/not-found.js'

const NotFound = (props) => {
    return (
        <MainLayout>
            <NotFoundComponent/>
        </MainLayout>
    )
}

export default NotFound