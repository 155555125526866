import React from 'react'
import './style.css';

const GalleryIcon = (props) => {
    const className = props.className ?? "small";

    return (
        <svg viewBox="0 0 1024 1024" className={className}
            onClick={() => props.onClick && props.onClick()}>
            <path d="M86 256h84v598h598v84h-598q-34 0-59-25t-25-59v-598zM470 512l-128 170h512l-172-212-126 158zM938 682q0 34-25 60t-59 26h-512q-34 0-60-26t-26-60v-512q0-34 26-59t60-25h512q34 0 59 25t25 59v512z"></path>
        </svg>
    )
}

export default GalleryIcon