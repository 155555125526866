
import React from 'react'

const Base64Image = (props) => {
    const contentType = props.contentType ?? "image/png"
    return (
        <img className={props.className} src={`data:${contentType};base64,${props.image}`} alt={props.alt} />
    )
}

async function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            resolve(reader.result)
        }
        reader.onerror = reject
    })
}

export { Base64Image as default, getBase64 }