import React, { useState } from 'react';
import './style.css'

import Base64Image from '../Utility/base-64-image'

const ItineraryItemCard = (props) => {
    const attributes = props.item.attributes.filter((attribute) => attribute.Key !== 'description')
    const descriptionAttribute = getDescriptionFromAttributes(props.item?.attributes)

    return (
        <div className="itinerary-item-card">
            {
                props.item.images.length > 0
                    ? props.item.images.map((image, id) =>
                        <Base64Image
                            alt={""}
                            image={image.content}
                            contentType={image.contentType}
                            className="itinerary-item-card-image"
                            key={id}
                        />
                    )
                    : <img alt="image"
                        src="https://play.teleporthq.io/static/svg/default-img.svg"
                        className="itinerary-item-card-image"
                    />
            }
            <h3>{props.item?.title}</h3>
            <span>{descriptionAttribute?.Value}</span>
            {
                attributes.map((attribute, index) =>
                    <div key={index}>
                        <span>{attribute.Key}:&nbsp;</span>
                        <span>{attribute.Value}</span>
                    </div>
                )
            }
        </div>
    )
}


const getDescriptionFromAttributes = (attributes) => {
    return attributes?.find((attribute) => attribute.Key === "description")
}

export default ItineraryItemCard;
