
import React, { useState } from 'react';

import Popup from '../../components/Popup/popup.js';
import CreateItineraryPopupContent from './create-itinerary-popup-content.js';

import FilterIcon from '../Icons/filter.js';
import AddChatIcon from '../Icons/add-chat.js';

import './style.css'


//TODO: Replace the add chat icon with one that is specific to itineraries

const ItineraryListControls = (props) => {
    const [addNewItineraryOpen, setAddNewItineraryOpen] = useState(false);

    return (
        <div>
            <Popup trigger={addNewItineraryOpen}
                setTrigger={setAddNewItineraryOpen}
                className="itineraries-list-add"
                contentClassName="add-new-itinerary-popup-content"
                showCloseButton={true}>
                <CreateItineraryPopupContent accountId={props.account?.Id} loadItineraries={props.loadItineraries} />
            </Popup>

            <div className="itineraries-list-header-controls">
                <FilterIcon className="small align-self-center" />

                <div style={ {display: "none"} }
                     className="add-new-itinerary-button-container"
                     onClick={() => setAddNewItineraryOpen(true)}>
                    <AddChatIcon className="small align-self-center" />
                </div>
            </div>
        </div>
    )
}

export default ItineraryListControls;