import React, { useEffect, useState } from 'react';
import Base64Image from '../../components/Utility/base-64-image.js';
import { useNavigate } from "react-router-dom";

import './style.css'
import ReactionToolbar from './reaction-toolbar.js';
import Popup from '../Popup/popup.js';
import { getChannelMembers } from '../../data/channel-data-access.js';
import { joinTrip } from '../../data/itinerary-data-access.js';
import CheckIcon from '../Icons/check.js';

const TripSuggestion = (props) => {
    const navigate = useNavigate();
    const [joinTripPopupOpen, setJoinTripPopupOpen] = useState(false);

    var tripDescription = props.properties?.find((value) => value.Key === "trip-description")?.Value;
    var tripName = props.properties?.find((value) => value.Key === "trip-name")?.Value;
    var itineraryId = props.properties?.find((value) => value.Key === "itinerary-id")?.Value;

    const goToItinerary = () => {
        navigate(`/Itineraries?itinerary=${itineraryId}`)
    }

    const showJoinTripPopupToUnjoinedUsers = async () => {
        var tripChannel = props.channel.TripChannels.find((x) => {
            return x.ItineraryId === parseInt(itineraryId)
        });

        if (!tripChannel)
            return;

        var channelMembers = await getChannelMembers(tripChannel.Id);

        console.log(channelMembers)
        if (channelMembers.length > 0) {
            var member = channelMembers.find((member) => member.Account.Id === props.account.Id && member.Status === 1);

            //The user is already in the trip we don't need to add them
            if (member)
                return;
        }

        setJoinTripPopupOpen(true)
    }

    //we should have a default image here
    return (
        <div className={"chat-group-focused-message-" + props.chatStyle}>
            <Popup trigger={joinTripPopupOpen}
                setTrigger={setJoinTripPopupOpen}
                className="itineraries-list-add"
                contentClassName="add-new-itinerary-popup-content"
                showCloseButton={true}>
                <JoinTripPopupContent account={props.account}
                    itineraryId={itineraryId}
                    itineraryName={tripName}
                    goToItinerary={goToItinerary}/>
            </Popup>
            <div className="link-preview" onClick={() => goToItinerary()}>
                <span>{props.chat.SentByAccount.DisplayName} suggested a new trip</span>
                {
                    props.chat?.Images && props.chat?.Images.length > 0 &&
                    <Base64Image
                        alt={""}
                        image={props.chat?.Images[0]?.Content}
                        contentType={props.chat?.Images[0]?.ContentType}
                        className="link-preview-image"
                    />
                }
                
                <span>{tripDescription}</span>
            </div>
            <ReactionToolbar chat={props.chat}
                account={props.account}
                refresh={props.refresh}
                likeAction={() => showJoinTripPopupToUnjoinedUsers()} />
        </div>
    )
}

const JoinTripPopupContent = (props) => {
    const [success, setSuccess] = useState(false);


    const _joinTrip = async () => {
        var res = await joinTrip(props.account.Id, props.itineraryId, null)

        if (res)
            setSuccess(true);
    }

    return (
        <div className="messages-panel-popup">
            {
                !success &&
                <>
                    <span>Would you like to join this trip?</span>
                    <button className="button" onClick={() => _joinTrip()}> Join Trip </button>
                </>
            }

            {
                success &&
                <>
                    <div style={{ display: "flex" }, { flexDirection: "row" }}>
                        <span>You have joined
                            {
                                " " + (props.itineraryName ?? "the trip")
                            }
                        </span>
                        <CheckIcon />
                    </div>
                    <button className="button" onClick={() => props.goToItinerary()}> View Trip </button>
                </>
            }
        </div>

    )
}

export default TripSuggestion
