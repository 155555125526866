import React from 'react';
import { useNavigate } from "react-router-dom";
import Base64Image from '../Utility/base-64-image';

import './friend-list.css';

const FriendList = ({ friends }) => {
    const navigate = useNavigate();

    return (
        <>
            {
                friends && friends.length > 0 &&
                friends.map((friend, index) =>
                    <div key={index}
                         onClick={() => navigate(`/profile/?username=${friend.Account.UserName}`)}
                        className="friend-list-container">
                        <div className="friend-list-item">
                            {
                                friend.Account.ProfileImage
                                    ? <Base64Image alt={friend.Account.DisplayName}
                                        image={friend.Account.ProfileImage.Content}
                                        contentType={friend.Account.ProfileImage.ContentType}
                                        className="friend-list-item-profile-picture"
                                    />
                                    : <img alt="image"
                                        src="https://play.teleporthq.io/static/svg/default-img.svg"
                                        className="friend-list-item-profile-picture"
                                    />
                            }
                            <div className="friend-list-item-name-container">
                                <span className="friend-list-item-primary-name">{friend.Account.DisplayName}</span>
                                <div>
                                    <span className="friend-list-item-secondary-name"> {friend.Account.Email} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default FriendList;
