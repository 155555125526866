import React, { useEffect, useState } from 'react';

import './style.css'

import { getLinkPreview } from '../../data/chat-data-access.js';

const LinkPreview = (props) => {
    const [previewData, setPreviewData] = useState();

    useEffect(() => {
        const load = async () => {
            var data = await getLinkPreview(props.url);
            setPreviewData(data);
        }

        if (!props.url)
            return;

        load();
    }, [props.url]);

    if (!previewData)
        return;

    var urlDescription = previewData.Description;
    if (previewData.Description?.length > 50)
        urlDescription = previewData.Description?.slice(0, 50) + "...";

    if (!previewData.ImageUrl || previewData.ImageUrl?.length === 0) {
        return (
            <div>
                <a href={props.url}>
                    <div className="link-preview">
                        <h3 className="link-preview-title">{previewData.Title}</h3>
                        <span className="link-preview-description">{urlDescription}</span>
                    </div>
                </a>
            </div>
        )
    }

    return (
        <div>
            <a href={props.url}>
                <div className="link-preview">
                    <img src={previewData.ImageUrl}
                        className="link-preview-image" />
                    <h3 className="link-preview-title">{previewData.Title}</h3>
                    <span className="link-preview-description">{urlDescription}</span>
                </div>
            </a>
        </div>
    )
}

export default LinkPreview

