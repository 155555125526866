import React, { useState } from 'react';
import CalendarIcon from '../Icons/calendar';
import Popup from '../Popup/popup';
import CreateTripControl from './create-trip';

const CreateTripButton = ({ channel, accountId, refresh, disabled }) => {
    const [popupOpen, setPopupOpen] = useState(false);

    return (
        <>
            <Popup trigger={popupOpen}
                   setTrigger={setPopupOpen}
                   className="itineraries-list-add"
                   contentClassName="add-new-itinerary-popup-content"
                   showCloseButton={true}>
                <CreateTripControl channel={channel}
                                   accountId={accountId}
                                   refresh={() => {
                                       refresh()
                                       setPopupOpen(false)
                                   }} />
            </Popup>

            <CalendarIcon onClick={() => !disabled && setPopupOpen(!popupOpen)} />
        </>
    )
}

export default CreateTripButton;

