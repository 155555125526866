import axios from 'axios';

async function getVotesByAccount(accountId) {
    try {
        var res = await axios.get("api/vote/account/" + accountId);
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

async function getVotesByGroupId(groupId) {
    try {
        var res = await axios.get("api/vote/group/" + groupId);
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

async function getPollById(voteId) {
    try {
        var res = await axios.get("api/vote/" + voteId);
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

async function createVote(request) {
    try {
        var res = await axios.post("api/vote/", request)
        console.log(res);

        return true;
    }
    catch (exception) {
        console.log(exception);
        return false;
    }
}

async function castVote(request, pollId) {
    try {
        var res = await axios.put("api/vote/" + pollId + "/cast", request)
        console.log(res);

        return true;
    }
    catch (exception) {
        console.log(exception);
        return false;
    }
}

async function resetAccountVote(pollId, accountId) {
    try {
        var res = await axios.put("api/vote/" + pollId + "/resetVote?", {
            params: {
                accountId: accountId
            }
        })
        console.log(res);

        return true;
    }
    catch (exception) {
        console.log(exception);
        return false;
    }
}

async function resetPollVotes(pollId) {
    try {
        var res = await axios.put("api/vote/" + pollId + "/resetVote", null)
        console.log(res);

        return true;
    }
    catch (exception) {
        console.log(exception);
        return false;
    }
}

async function finishPoll(pollId) {
    try {
        var res = await axios.put("api/vote/" + pollId + "/FinishPoll", null)
        console.log(res);

        return true;
    }
    catch (exception) {
        console.log(exception);
        return false;
    }
}

async function updatePoll(request) {
    try {
        var res = await axios.put("api/vote/", request)
        console.log(res);

        return true;
    }
    catch (exception) {
        console.log(exception);
        return false;
    }
}

async function removePoll(id, removedBy) {
    try {
        var res = await axios.delete("api/vote/" + id + "?", {
            params: {
                removedBy: removedBy
            }
        })
        console.log(res);

        return true;
    }
    catch (exception) {
        console.log(exception);
        return false;
    }
}

async function removeVotingOptionAttribute(id, removedBy) {
    try {
        var res = await axios.delete("api/vote/optionattribute")
        console.log(res);

        return true;
    }
    catch (exception) {
        console.log(exception);
        return false;
    }
}

export { getVotesByAccount, getVotesByGroupId, getPollById, createVote, castVote, updatePoll, removePoll, removeVotingOptionAttribute, resetAccountVote, resetPollVotes, finishPoll };