import axios from 'axios';

async function getChannelMessages(channelId, pageIndex, accountId) {
    try {
        var res = await axios.get("api/chat?", {
            params: {
                channelId: channelId,
                pageOffset: pageIndex,
                accountId: accountId,
                rowsPerPage: 50
            }
        });
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

async function getLinkPreview(url) {
    try {
        var res = await axios.get("api/chat/LinkPreview?", {
            params: {
                url: url
            }
        });
        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return null;
    }
}

async function markChatAsRead(chatId, accountId) {
    var res = await axios.put("api/chat/" + chatId,
        {
            accountId: accountId
        })

    console.log(res);
    return res.data;
}

async function reactToChat(chatId, accountId, reaction) {
console.log(reaction)

    var res = await axios.put("api/chat/" + chatId
        + "/react?accountId=" + accountId
        + "&reaction=" + reaction, null)

    console.log(res);
    return res.data;
}

async function removeChatReaction(chatId, accountId) { 
    var res = await axios.delete("api/chat/" + chatId + "/unreact?", {
            params: {
                accountId: accountId
            }
        })

    console.log(res);
    return res.data;
}

async function sendChat(request) {
    var res = await axios.post("api/chat/", request)

    console.log(res);
    return res.data;
}

export { getChannelMessages, getLinkPreview, markChatAsRead, sendChat, reactToChat, removeChatReaction }
