import React, { useState } from "react";
import { getAccount, searchAccount } from "../../data/account-data-access";
import CheckIcon from "../Icons/check";
import CloseIcon from "../Icons/close";
import PaperAirplaneIcon from "../Icons/paper-airplane";
import { isNullOrWhitespace } from "../Utility/string-util";

import './find-user-control.css';

const FindUserControl = (props) => {
    const [searchText, setSearchText] = useState("");
    const [searchTextBoxHasFocus, setSearchTextBoxHasFocus] = useState(false);
    const [userInputErrors, setUserInputErrors] = useState();
    const [showNoUserFound, setShowNoUserFound] = useState(false);
    const [showInviteUser, setShowInviteUser] = useState(false);
    const [accountResults, setAccountResults] = useState([]);

    const handleInput = async e => {
        if (e.target.value === '\n') {
            return;
        }

        setShowInviteUser(false);
        setSearchText(e.target.value);

        var searchResults = await findAccounts(e.target.value)
        setAccountResults(searchResults)
    }

    const addOnEnter = e => {
        if (e.key === 'Enter') {
            addMember();
        }
    }

    const findAccounts = async (search) => {
        let emailMatch = new RegExp(".+[@].+\..").exec(search)
        var accountResults = [];

        if (isNullOrWhitespace(search)) {
            setUserInputErrors();
            return accountResults;
        }

        if (emailMatch) {
            var result = await getAccount(search, null);
            console.log(result)

            if(result)
                accountResults = [result]
        }
        else {
            //see if they actually typed in a username that we can match
            accountResults = await searchAccount(search);
        }

        //no matches found, but this is an email
        if (accountResults?.length === 0 && emailMatch) {
            setShowInviteUser(true);//ask if they want to invite them
            setUserInputErrors();
            return [
                {
                    Email: search,
                    DisplayName: search,
                    InviteNeeded: true,
                    Id: Date.now() % 100000 //This makes a random int guaranteed for 100 seconds. This is a cheap way to make each new account identifiable, it will probably fail weirdly once in a blue moon
                }
            ];
        }
        else if (accountResults?.length === 0) {
            setUserInputErrors(<span>We couldn&apos;t find this user. <br/> Invite them by entering their email.</span>);
            return;
        }

        setUserInputErrors();
        return accountResults;
    }

    const addMemberFromSearch = async () => {
        setUserInputErrors();
        setShowInviteUser(false);
        var accountResults = findAccounts(searchText);

        if (!accountResults || accountResults.length === 0) {
            setShowNoUserFound(true);
            return;
        }

        addMember(accountResults[0])
    }

    const addMember = async (account) => {
        var newMemberResult = props.addMember(accountResults[0])
        if (newMemberResult.length > 0) {
            setUserInputErrors(newMemberResult);
            return;
        }

        setSearchText("");
        setAccountResults([]);
    }

    return (
        <div className="find-user-control-container">
            <div className="add-trip-member-input-section">
                <div className="add-trip-member-input-container">
                    <input type="text"
                        placeholder="Email or user name..."
                        className="channel-member-input"
                        value={searchText}
                        onChange={handleInput}
                        onKeyDown={addOnEnter}
                        onFocus={() => setSearchTextBoxHasFocus(true)}
                        onBlur={() => setSearchTextBoxHasFocus(false)}
                    />

                    <CheckIcon className="add-trip-member-check-icon extra-small-2" onClick={() => addMemberFromSearch()} />
                </div>
                {
                    searchTextBoxHasFocus && accountResults && accountResults.length > 0 &&
                    <div className="suggestion-results">
                        {
                            accountResults.map(
                                (result, index) => (
                                    <button className="suggestion-result-item-container" key={index} onMouseDown={() => addMember(result)}>
                                        {
                                            result.InviteNeeded &&
                                            <div className="suggestion-result-item-invite-container">
                                                <span> Invite {result.Email}</span>
                                                <PaperAirplaneIcon className="extra-small-2" />
                                            </div>
                                        }
                                        {
                                            !result.InviteNeeded &&
                                            <span>{result.DisplayName}</span>
                                        }                                        
                                    </button>
                                )
                            )
                        }
                    </div>
                }
            </div>

            {
                props.showInviteUser && showInviteUser &&
                <div className="add-trip-member-no-user-found-container">
                    <span className="add-trip-member-no-user-found-text"> We couldn't find that user. Would you like to invite them?
                        <button className="add-trip-member-no-user-found-invite-button button">
                            <span> Send Invite </span>
                            <PaperAirplaneIcon className="add-trip-member-send-invite-button-icon extra-small-2" />
                        </button>
                    </span>
                </div>
            }

            {
                userInputErrors &&
                <span className="add-trip-member-input-errors">
                    {userInputErrors}
                </span>
            }            
        </div>
    )
}

export default FindUserControl;