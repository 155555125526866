import React, { useState, useEffect } from 'react';
import { getAccountFriends } from '../../data/friend-data-access';
import FriendList from './friend-list';

const AccountFriendList = ({ accountId }) => {
    const [friends, setFriends] = useState([]);

    useEffect(() => {
        const loadFriends = async () => {
            await _getFriends(accountId);
        }

        loadFriends();
    }, [accountId]);

    const _getFriends = async (accountId) => {
        if (accountId === 0)
            return;

        var res = await getAccountFriends(accountId);

        if (res?.length > 0)
            setFriends(res);
    }

    return (
        <div>
            <h2>Friends:</h2>
            <FriendList friends={friends} />
        </div>
    )

}

export default AccountFriendList;

