import React from 'react';
import CloseCircleIcon from '../Icons/close-circle.js';
import './rounded-attribute-container.css';

const RoundedAttributeContainer = (props) => {
    return (
        <div className="rounded-attribute-container">
            <p className="rounded-attribute-container-text">{props.name}</p>
            <CloseCircleIcon className="rounded-attribute-container-delete-icon" onClick={() => props.remove()}/>
        </div>
    )
}

export default RoundedAttributeContainer