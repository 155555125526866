import React, { forwardRef } from 'react';

import LinkPreview from './link-preview';
import ChatImage from './chat-image.js';
import TripSuggestion from './trip-suggestion';
import EventSuggestion from './event-suggestion';
import './style.css'
import MemberAdded from './member-added';
import ReactionToolbar from './reaction-toolbar';

const Message = forwardRef(function Message(props, ref) {
    var chatStyle = props.chat.SentByAccount.Id === props.user.Id
        ? "sent"
        : "received";

    const format = new Intl.DateTimeFormat("en", {
        timeStyle: "short",
    });

    var formattedTime = format.format(new Date(props.chat.SentDateTime))

    const embeddedLink = props.chat.MessageBody?.match(/(?:\(\"(.+)\"\)\[\"((?:https|http)\:\/\/.*\..*)\"\])/) ?? [];

    const plainTextLink = props.chat.MessageBody?.match(/(?:\s|^)(?:https|http)\:\/\/.*\.\S*\s*/) ?? [];
    var linkPreview = null;
    if (embeddedLink.length > 0) {
        linkPreview = <LinkPreview url={embeddedLink[2]} />
    }

    if (plainTextLink.length > 0) {
        linkPreview = <LinkPreview url={plainTextLink[0]} />
    }

    var embeddedJson = props.chat.MessageBody?.match(/^(?:\["type:json"])/) ?? [];

    if (embeddedJson.length > 0) {
        var json = props.chat.MessageBody?.substring(embeddedJson[0].length)
        var object = JSON.parse(json);

        if(object.Type === "trip-suggestion")
            return (<TripSuggestion chat={props.chat}
                properties={object.Properties}
                chatStyle={chatStyle}
                account={props.user}
                refresh={props.refresh}
                channel={props.channel}
            />)
        else if (object.Type === "trip-event-suggestion")
            return (<EventSuggestion chat={props.chat}
                properties={object.Properties}
                chatStyle={chatStyle}
                account={props.user}
                refresh={props.refresh} />)
        else if (object.Type === "user-joined-channel")
            return (<MemberAdded chat={props.chat} properties={object.Properties} chatStyle={chatStyle} />)
    }

    //we need to do something here to render plain text links inline, but idk what that is
    const getFormattedMessage = (message, idx) => {
        if (linkPreview == null)
            return <span key={idx}> {message} </span>;

        if (!message.includes(embeddedLink[0]))
            return <span key={idx}> {message} </span>;

        return (
            <span key={idx}>
                {message.substring(0, embeddedLink.index)}
                <a href={embeddedLink[2]}>
                    {embeddedLink[1]}
                </a>
                {message.substring(embeddedLink.index + embeddedLink[0].length)}
            </span>)
    }

    return (
        <div className={"chat-group-focused-message-" + chatStyle} ref={ref}>
            {
                props.chat.Images && props.chat.Images.length > 0 &&
                props.chat.Images.map((image, index) => {
                    return <ChatImage image={image} key={index}/>
                })
            }
            <div className="chat-group-focused-container5">
                {
                    props.chat.SentByAccount.Id !== props.user.Id &&
                    <span className="chat-group-focused-text5">{props.chat.SentByAccount.Name}</span>
                }
                <span className="chat-group-focused-text4">{formattedTime}</span>
            </div>
            {
                props.chat.MessageBody.split("\n").map((i, key) => {
                    return (getFormattedMessage(i, key));
                })
            }
            {
                linkPreview
            }
            <ReactionToolbar chat={props.chat} account={props.user} refresh={props.refresh} />
        </div>
    )
});

export default Message