import React from 'react'
import './style.css';

const CloseIcon = (props) => {
    const className = props.className ?? "small";

    return (
        <svg viewBox="0 0 1024 1024" className={className}
            onClick={() => props.onClick && props.onClick()}>
            <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
        </svg>
    )
}

export default CloseIcon