import React from 'react'
import './style.css'

const NotFoundComponent = (props) => {
    return (
        <div className="not-found-container1">
            <h1> <b>404</b> </h1>
            <h2 className="not-found-text2">
                The page you requested was not found.
            </h2>
        </div>
    )
}

export default NotFoundComponent