import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate } from "react-router-dom";
import axios from 'axios';
import './style.css'

import Loading from '../../components/Loading/loading.js';

import { searchAccount } from '../../data/account-data-access.js'

const ProfileSearchResults = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [results, setResults] = useState([]);

    useEffect(() => {
        const load = async () => {
            var search = searchParams.get("search");
            await loadProfiles(search);
        }

        load();
    }, []);

    useEffect(() => {
        const load = async () => {
            await loadProfiles(props.search);
        }

        load();
    }, [props.search]);

    const loadProfiles = async (search) => {
        if (!search)
            return;

        setLoading(true);
        var res = await searchAccount(search);
        setResults(res);
        setLoading(false);
    }

    if (loading && (props.showLoading ?? false))
        return (<Loading/>);

    return (
        <div className="suggestion-results">
            {
                (results?.length ?? 0) === 0 &&
                <span> No users found </span>
            }
            {
                results && results.length > 0 &&
                results.map(
                    (result, index) => (
                        <button className="suggestion-result-item-container" onMouseDown={() => navigate(`/profile/?username=${result.UserName}`)}>
                            <div className="suggestion-result-item">
                                <p>{result.UserName}</p>
                            </div>
                        </button>
                    )
                )
            }
        </div>
    )
}

export default ProfileSearchResults
