import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import moment from 'moment'

import PageControl from '../Controls/page-control.js';
import Popup from '../Popup/popup';
import Message from './message';
import Base64Image, { getBase64 } from '../../components/Utility/base-64-image.js';
import TripMemberControl from '../Trip/trip-member-control.js';

import { getChannelMessages } from '../../data/chat-data-access.js';
import { createChannel, updateChannelName, deleteChannel } from '../../data/channel-data-access.js';
import { getAccount, searchAccount } from '../../data/account-data-access';
import { createTrip, suggestEvent } from '../../data/itinerary-data-access';

import EditSquareIcon from '../Icons/edit-square';
import CalendarIcon from '../Icons/calendar';
import GalleryIcon from '../Icons/gallery';
import EllipsesIcon from '../Icons/ellipses';
import PaperAirplaneIcon from '../Icons/paper-airplane';
import PaperClipIcon from '../Icons/paperclip';
import EditIcon from '../Icons/edit';
import SettingsIcon from '../Icons/settings';
import CloseIcon from '../Icons/close';
import LinkIcon from '../Icons/link';
import CheckIcon from '../Icons/check.js';
import './style.css'
import CreateTripButton from '../Trip/create-trip-button.js';

const MessagesPanel = (props) => {
    const [messages, setMessages] = useState(props.channel?.Chats ?? [])
    const [input, setInput] = useState('');
    const [pageIndex, setPageIndex] = useState(0);
    const [singleLineMode, setSingleLineMode] = useState(true);
    const [groupName, setGroupName] = useState(props.channel?.ChannelName)
    const [editingGroupName, setEditingGroupName] = useState(false);
    const [selectedImages, setSelectedImages] = useState([])

    // Changing this forces react to rerender the input. If we don't do this,
    // react holds the input state and you will not be able to upload the same image in two different channels 
    const [imageInputKey, setImageInputKey] = useState(0);
    const [linkPopupOpen, setLinkPopupOpen] = useState(false);
    const [addEventPopupOpen, setAddEventPopupOpen] = useState(false);
    const [settingsPopupOpen, setSettingsPopupOpen] = useState(false);

    const firstMessage = useRef(null);
    const newChannelNotSaved = props.channel?.Id === -1;
    const fileInput = useRef(null);

    useEffect(() => {
        loadChannelMessages(false);
        setGroupName(props.channel?.ChannelName);
        setInput('');
        setSelectedImages([]);
        setEditingGroupName(false);
        setSingleLineMode(true);
        setPageIndex(0);
        setImageInputKey(props.channel?.Id ?? 0);
    }, [props.channel]);

    useEffect(() => {
        giveGroupNameTextBoxFocus();
    }, [editingGroupName])

    useEffect(() => {
        if (firstMessage.current) {
            firstMessage.current.scrollIntoView({ block: "nearest", inline: "nearest" });
        }
    });

    const loadChannelMessages = async (force) => {
        if (!props.channel)
            return;

        if (!force && (props.channel?.Chats?.length > 0 ?? false)) {
            setMessages(props.channel.Chats);
            return;
        }

        var res = await getChannelMessages(props.channel.Id, pageIndex, props.user.Id);

        setMessages(res);
        props.channel.Chats = res;
    }

    const handleInput = e => {
        if (e.target.value === '\n' && singleLineMode) {
            return;
        }

        setInput(e.target.value);
    }

    const sendOnEnter = e => {
        if (e.key === 'Enter' && singleLineMode) {
            send();
        }
    }

    const send = () => {
        if (input && input != '' || selectedImages.length > 0) {
            props.onSendMessage(props.channel.Id, input, selectedImages);
            setInput('');
            setSelectedImages([]);
        }
    }

    const toggleEditingGroupName = () => {
        setEditingGroupName(!editingGroupName)
    }

    const handleGroupNameChange = e => {
        if (e.target.value === '\n') {
            return;
        }

        setGroupName(e.target.value)
    }

    const saveGroupNameOnEnter = e => {
        if (e.key === 'Enter') {
            saveGroupName();
        }
    }

    const saveGroupName = async () => {
        if (groupName === props.channel?.ChannelName) {
            setEditingGroupName(false);
            return;
        }

        if (props.channel?.Id === -1) {
            await createChannel(props.user.Id, null, groupName);
        }
        else {
            await updateChannelName(props.channel?.Id, groupName);
        }

        props.setRefreshNeeded(true);
    }

    const giveGroupNameTextBoxFocus = () => {
        var textArea = document.getElementById("edit-group-name-textarea");
        textArea?.focus()
        textArea?.setSelectionRange(textArea.value.length, textArea.value.length);
    }

    const handleFileUploadClick = event => {
        fileInput.current.click();
    };

    const handleFileSelection = async (file) => {

        console.log(file)

        let dataUrl = await getBase64(file);

        console.log(selectedImages)

        let imageData = dataUrl.replace('data:', '').replace(/^.+,/, '');
        let contentType = new RegExp("(?:image\/).*(?=\;)").exec(dataUrl)[0]
        var image = {
            FileName: crypto.randomUUID(),
            CreatedBy: props.user.Id,
            Content: imageData,
            ContentType: contentType
        }

        console.log(image)
        setSelectedImages([...selectedImages, image]);
    }

    const removeImage = (fileName) => {
        setSelectedImages(selectedImages.filter((image) => image.FileName !== fileName));
    }

    let list = <div className="no-content-message">There are no messages to show</div>;
    if (props.channel && messages.length > 0) {
        list = messages.map((chat, index) =>
            <Message key={chat.Id}
                id={chat.Id}
                chat={chat}
                user={props.user}
                channel={props.channel}
                ref={index === messages.length - 1 ? firstMessage : null}
                refresh={() => loadChannelMessages(true)}/>
        );
    }

    var groupNameField = <h1 className="chat-group-focused-text3">
                            <span>{props.channel?.ChannelName}</span>
                         </h1>;

    if (editingGroupName) {
        groupNameField = <textarea className="chat-group-focused-edit-group-name-textarea textarea"
            onChange={handleGroupNameChange}
            value={groupName}
            onKeyDown={saveGroupNameOnEnter}
            onBlur={saveGroupName}
            id="edit-group-name-textarea"/>
    }

    return (
        <div className="chat-group-focused-container3">
            <div className="chat-group-focused-chats">
                <div className="chat-group-focused-channel-tools">
                    <div className="chat-group-focused-group-name-container">
                        {
                            groupNameField
                        }
                        <EditIcon className="chat-group-focused-edit-group-name" onClick={() => toggleEditingGroupName()}/>
                    </div>
                    <div className="chat-group-focused-group-tools-container">
                        <TripMemberControl account={props.user}
                            channel={props.channel}
                            refresh={() => props.setRefreshNeeded(true)}
                            canOpen={() => !newChannelNotSaved}/>

                        <Popup trigger={settingsPopupOpen}
                            setTrigger={setSettingsPopupOpen}
                            className="itineraries-list-add"
                            contentClassName="add-new-itinerary-popup-content"
                            showCloseButton={true}>
                            <SettingsPopupContent account={props.user}
                                                  channel={props.channel}
                                                  refresh={() => props.setRefreshNeeded(true)}
                                                  closePopup={() => setSettingsPopupOpen(false)}/>
                        </Popup>
                        <SettingsIcon onClick={() => !newChannelNotSaved && setSettingsPopupOpen(!settingsPopupOpen)} />
                    </div>
                </div>
                <div className="chat-group-focused-messages">
                    { list }
                </div>
                <div className="chat-group-focused-message-tools" style={{ height: singleLineMode ? "75px" : "unset" }}>
                    {
                        selectedImages.length > 0 &&
                        selectedImages.map((image, index) =>
                            <AddedImage removeImage={removeImage}
                                image={image}
                                key={index}/>
                        )
                            
                    }

                    <textarea className="chat-group-focused-textarea textarea"
                        onChange={handleInput}
                        value={input}
                        onKeyDown={sendOnEnter}
                        disabled={newChannelNotSaved}/>
                    <div className="chat-group-focused-container7">
                        <div className="chat-group-focused-text-tools">
                            <input key={imageInputKey}
                                type="file"
                                name="image"
                                ref={fileInput}
                                onChange={(event) => {
                                    console.log(event.target.files[0]);
                                    handleFileSelection(event.target.files[0]);
                                }}
                                style={{ display: 'none' }}
                            />
                            <Popup trigger={linkPopupOpen}
                                setTrigger={setLinkPopupOpen}
                                className="itineraries-list-add"
                                contentClassName="add-new-itinerary-popup-content"
                                showCloseButton={true}>
                                <LinkPopupContent account={props.user} />
                            </Popup>

                            <EditSquareIcon onClick={() => !newChannelNotSaved && setSingleLineMode(!singleLineMode)}/>
                            <GalleryIcon onClick={() => !newChannelNotSaved && handleFileUploadClick()} />
                            <CreateTripButton accountId={props.user.Id}
                                channel={props.channel}
                                refresh={() => props.setRefreshNeeded(true)} />
                            <LinkIcon onClick={() => !newChannelNotSaved && setLinkPopupOpen(!linkPopupOpen)} />
                        </div>
                        <div className="chat-group-focused-send-button-container" onClick={send}>
                            <PaperAirplaneIcon />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ); 
}

const AddedImage = (props) => {
    if (!props.image)
        return;

    return (
        <div className="added-image-container">
            <Base64Image
                alt={""}
                image={props.image.Content}
                contentType={props.image.ContentType}
                className="chat-added-image"
            />
            <CloseIcon onClick={() => props.removeImage(props.image.FileName)}/>
        </div>
    )
}

const SettingsPopupContent = (props) => {
    const deleteThisChannel = async () => {
        var res = await deleteChannel(props.channel.Id)
        props.refresh();
        props.closePopup();
    }

    return (
        <div>
            <button className="button destructive-button" onClick={() => deleteThisChannel()}>Delete Channel</button>
        </div>
    )
}

const LinkPopupContent = (props) => {
    return (
        <div className="messages-panel-popup">
        </div>
    )
}


//I think this is what I am wanting for this:
/*
    First Page: show all "linked itineraries" and give an option to create an itinerary
        - if the user selects an itinerary
            - give the option to delete the itinerary
            - give the option to view the itinerary
        - if the user enters an itinerary name in the create box
            - set the create button as active, create notifies chat and navigates user to itinerary page

    //Don't think we want a second page at the moment
    //Do we want something on this page that lets the user select an event? suggest alternatives? it would be easier to just keep it all on the itineraries page
    Second Page: prompt the user to create a new event

    *linking means the itinerary will be attached to the group of the chat? Or a new group will be made from the chat members?
        - more importantly, changes to the itinerary will notify the chat
        - additionally, changes to the poll/vote (new vote, expiration, etc...) will notify the chat
        - on itinerary creation, we will probably have to have a user select a chat to link it to or create a new chat

    each group can have multiple chats and multiple itineraries we need the one chat to many itineraries "link" because things could get messy mapping through the group
        - this is also a good idea because if you have only some people in your group who want to go on a trip, you can make a group chat just for them
    if an itinerary is "finished" or deleted it should not show up in the list of linked itineraries

    *** I like this a lot more
    should we instead just make a separate group chat for every itinerary?
        - flow would look like this:
            - in main group chat, user suggests itinerary to the group
            - any user that wants to join that trip can first view the itinerary as it exists today, and then opt into that trip
            - opting in would put them in a new sub group chat "linked" to the itinerary
            - sub group chats are not allowed to create sub group chats or itineraries of their own, sort of like discord with a main channel and side channels
                - clicking on the calendar in main would prompt you to suggest a new trip, clicking on the calendar in "trip" chat would take you to the itinerary for that page
            - only downside is that there might be a bunch of past trips that we don't want to show
                - we could add an archive tab or something
            - we would probably want to show something that says if a user has decided to join the trip, is undecided, or opted out
            - if the user just generates an itinerary through the itinerary page
                - without selecting a group
                    - create new group
                    - create new itinerary
                    - create new group chat
                    - create new trip group chat
                        - link trip group chat to itinerary
                        - link to trip chat to parent chat
                    - send notification in new group chat
                - with a group selected
                    - create new itinerary
                    - create new trip group chat
                        - link trip group chat to itinerary
                    - send notification in existing group chat

        - implementation:
            - build a 1 to 1 channel to itinerary table -- done
            - add a optional column onto channel "main channel" that links a channel back to its parent -- done
                - that would mean there are three states a channel could be in
                    - side conversation: multiple users that aren't in a travel group 
                        - if they click the calendar we will show them the suggest a trip page but we need to
                            - create a new group and set the group id on the existing chat
                            - create new itinerary
                            - create new trip group chat
                                - link trip group chat to itinerary
                                - link to trip chat to parent chat
                            - send notification in new group chat
                    - main travel group chat: group id is set
                    - trip group chat: main channel id and group id are set
            - new getter gt_SubChats(@ChannelId) -- done
            - every time a user joins the trip we would need to update the channel members for the corresponding chat -- done
            - this will fuck up notifications since they are currently based off of the group id -- done
                - we would only want to notify users that are both in the group and opted into the trip
            - if i remove someone from the main channel, they need to be removed from all sub channels as well
            - if i delete an itinerary, the trip group chat should be deleted as well -- done

        - create trip
            - request needs to have:
                - group id OR new group name (is this the same as the itinerary? Probably not?)
                - trip name
                - Created By Account
                - nullable existing chat id


*/
const ItineraryPopupContent = (props) => {
    const [newTripName, setNewTripName] = useState("");
    const [newGroupName, setNewGroupName] = useState("");
    const [tripDescription, setTripDescription] = useState("");

    const [newEventName, setNewEventName] = useState("");
    const [startDate, setStartDate] = useState(moment(new Date()).format("YYYY-MM-DDTHH:mm"));
    const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DDTHH:mm"));
    const [eventDescription, setEventDescription] = useState("");

    const [imageInputKey, setImageInputKey] = useState(0);
    const [selectedImage, setSelectedImage] = useState()
    const [createNewEventActive, setCreateNewEventActive] = useState(false);

    const fileInput = useRef(null);
    const navigate = useNavigate();

    const goToItinerary = () => {
        navigate(`/Itineraries?itinerary=${props.channel?.ItineraryId}`)
    }

    const handleFileUploadClick = event => {
        fileInput.current.click();
    };

    const handleFileSelection = async (file) => {
        let dataUrl = await getBase64(file);

        let imageData = dataUrl.replace('data:', '').replace(/^.+,/, '');
        let contentType = new RegExp("(?:image\/).*(?=\;)").exec(dataUrl)[0]
        var image = {
            FileName: crypto.randomUUID(),
            CreatedBy: props.account.Id,
            Content: imageData,
            ContentType: contentType
        }

        setSelectedImage(image);
    }

    const _createTrip = async () => {
        var request = {
            ItineraryName: newTripName.length > 0 ? newTripName : null,
            GroupName: newGroupName.length > 0 ? newGroupName : null,
            CreatedByAccountId: props.account.Id,
            GroupId: props.channel.GroupId,
            TripDescription: tripDescription,
            TripSuggestionImage: selectedImage,
            MainChannelId: props.channel.Id
        }

        var res = await createTrip(request)

        if (!res) {
            console.log("failed to create trip")
            return;
        }

        props.refresh();
    }

    //need to keep a collection of attributes here, for now we will only support description
    const _suggestEvent = async () => {
        var request = {
            Name: newEventName,
            StartDate: startDate,
            EndDate: endDate,
            Attributes: [
                {
                    Key: "description",
                    Value: eventDescription
                }
            ],
            Images: [
                selectedImage
            ]
        }

        var res = await suggestEvent(props.channel.ItineraryId, props.account.Id, request);

        if (!res) {
            console.log("failed to suggest event")
            return;
        }

        props.refresh();
    }

    const handleInput = (e, setter) => {
        if (e.target.value === '\n') {
            return;
        }

        setter(e.target.value)
    }

    var pages = [
        {
            canContinue: newTripName.length > 0,
            content: <>
                <p>Create a trip:</p>
                <span>Name your trip:</span>
                <input type="text"
                    placeholder="Trip Name..."
                    className="channel-member-input"
                    value={newTripName}
                    onChange={(e) => handleInput(e, setNewTripName)}
                />
            </>
        },
        {
            canContinue: tripDescription.length > 0,
            content: <>
                <p>Create a trip:</p>
                <span>Describe your vision:</span>

                <input type="text"
                    placeholder="Write your pitch here..."
                    className="channel-member-input"
                    value={tripDescription}
                    onChange={(e) => handleInput(e, setTripDescription)}
                />

                {
                    selectedImage &&
                    <Base64Image
                        alt={""}
                        image={selectedImage.Content}
                        contentType={selectedImage.ContentType}
                        className="chat-added-image"
                    />
                }

                <input key={imageInputKey}
                    type="file"
                    name="image"
                    ref={fileInput}
                    onChange={(event) => {
                        console.log(event.target.files[0]);
                        handleFileSelection(event.target.files[0]);
                    }}
                    style={{ display: 'none' }}
                />

                <button className="button" onClick={() => handleFileUploadClick()}>
                    {
                        selectedImage ? "Change Photo" : "Attach a photo"
                    }
                </button>
            </>
        }
    ];

    if (props.channel?.ItineraryId) {
        if (createNewEventActive) {
            pages = [
                {
                    canContinue: newEventName.length > 0,
                    content: <>
                        <p>Suggest an event:</p>
                        <span>Name your event:</span>
                        <input type="text"
                            placeholder="Event Name..."
                            className="channel-member-input"
                            value={newEventName}
                            onChange={(e) => handleInput(e, setNewEventName)}
                        />
                    </>
                },
                {
                    canContinue: true,
                    content: <>
                        <p>Suggest an event:</p>
                        <span>When will your event take place?</span>
                        <label className="trip-add-event-label">
                            Start Date:
                        </label>

                        <input
                            type="datetime-local"
                            className="trip-add-event-input"
                            onChange={(event) => {
                                setStartDate(event.target.value)
                            }}
                            value={startDate}
                        />


                        <label className="trip-add-event-label">
                            End Date:
                        </label>

                        <input
                            type="datetime-local"
                            className="trip-add-event-input"
                            onChange={(event) => {
                                setEndDate(event.target.value)
                            }}
                            value={endDate}
                        />
                    </>
                },
                //we need to do something here to ask if the user would like to create a vote for events that overlap
                {
                    canContinue: eventDescription.length > 0,
                    content: <>
                        <p>Suggest an event:</p>
                        <span>Describe your vision:</span>

                        <input type="text"
                            placeholder="Write your pitch here..."
                            className="channel-member-input"
                            value={eventDescription}
                            onChange={(e) => handleInput(e, setEventDescription)}
                        />

                        {
                            selectedImage &&
                            <Base64Image
                                alt={""}
                                image={selectedImage.Content}
                                contentType={selectedImage.ContentType}
                                className="chat-added-image"
                            />
                        }
                       
                        <input key={imageInputKey}
                            type="file"
                            name="image"
                            ref={fileInput}
                            onChange={(event) => {
                                console.log(event.target.files[0]);
                                handleFileSelection(event.target.files[0]);
                            }}
                            style={{ display: 'none' }}
                        />

                        <button className="button" onClick={() => handleFileUploadClick()}>
                            {
                                selectedImage ? "Change Photo" : "Attach a photo"
                            }
                        </button>

                    </>
                }
            ];

            return (
                <div className="messages-panel-popup">
                    <PageControl submitText="Create"
                        submitAction={() => _suggestEvent()}
                        pages={pages} />
                </div>
            )
        }

        return (
            <div className="messages-panel-popup">
                <button className="button" onClick={() => goToItinerary()}> View Trip </button>

                <button className="button" onClick={() => setCreateNewEventActive(true) }> Suggest a new event </button>
            </div>
        )
    }

    if (!props.channel?.GroupId) {
        pages = [
            {
                canContinue: newGroupName.length > 0,
                content: <>
                    <p>Create a trip:</p>
                    <span>First, create a new group:</span>
                    <input type="text"
                        placeholder="Group Name..."
                        className="channel-member-input"
                        value={newGroupName}
                        onChange={(e) => handleInput(e, setNewGroupName)}
                    />
                </>
            },
            ...pages
        ]
    }

    return (
        <div className="messages-panel-popup">
            <PageControl submitText="Create"
                submitAction={() => _createTrip()}
                pages={pages} />
        </div>
    )
}

export default MessagesPanel