import React, { useState, useCallback, useMemo } from 'react';
import moment from 'moment'
import { Calendar, Views, momentLocalizer, DateLocalizer } from 'react-big-calendar'

import './style.css'
import 'react-big-calendar/lib/sass/styles.scss';

const DashboardItineraryCalendar = (props) => {
    const localizer = momentLocalizer(moment)
    const [itinerary, setItinerary] = useState();
    //const [addNewItineraryItemOpen, setAddNewItineraryItemOpen] = useState(false);
    const [selectedStartEndDateRange, setSelectedStartEndDateRange] = useState();

    const { defaultDate, scrollToTime, formats } = useMemo(
        () => ({
            defaultDate: itinerary?.StartDate ?? new Date(),
            scrollToTime: new Date(1970, 1, 1, 6),
            formats: {
                timeGutterFormat: (date, culture, localizer) =>
                    localizer.format(date, 'h:mm a', culture),
                dayFormat: (date, culture, localizer) =>
                    localizer.format(date, 'ddd', culture),
            }
        }),
        []
    )

    //useEffect(() => {
    //    if (!addNewItineraryItemOpen)
    //        setSelectedStartEndDateRange();

    //}, [addNewItineraryItemOpen])

    const handleSelectSlot = useCallback(
        ({ start, end }) => {
            setSelectedStartEndDateRange({
                StartDate: start,
                EndDate: end
            })
            //setAddNewItineraryItemOpen(true);
        },
        []
    )

    const handleSelectEvent = useCallback(
        (event) => window.alert(event.title),
        []
    )

    var events = [];

    if ((props.itineraries?.length ?? 0) > 0) {
        props.itineraries.map((itinerary, index) => {
            (itinerary.Items?.length ?? 0) > 0 &&
                itinerary.Items.map((item, index) => {
                    events.push({
                        id: item.Id,
                        title: item.Name,
                        start: new Date(item.StartDate),
                        end: new Date(item.EndDate)
                    })
                })
        })
    }

    return (
        <div className="itinerary-calendar-container">
            <Calendar
                defaultDate={defaultDate}
                defaultView={Views.WEEK}
                localizer={localizer}
                events={events}
                style={{ height: 700 }}
                selectable
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                dayLayoutAlgorithm="no-overlap"
                defaultView={Views.WEEK}
                formats={formats}
            />
        </div>
    )
}

export default DashboardItineraryCalendar