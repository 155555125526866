import React, { useState, useEffect, forwardRef, useRef } from 'react';
import { HubConnectionState, HubConnectionBuilder, LogLevel, HttpTransportType } from "@microsoft/signalr";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";

import MainLayout from '../../components/MainLayout/main-layout.js';
import Loading from '../../components/Loading/loading.js';
import MessagesPanel from '../../components/Chat/messages-panel.js';
import ChannelList from '../../components/Chat/channel-list.js';
import ChannelListControls from '../../components/Chat/channel-list-controls.js'; import './style.css'

import { getChannelMessages, getLinkPreview, markChatAsRead, sendChat } from '../../data/chat-data-access.js';
import { getGroups, createGroup, addGroupMember, removeGroupMember } from '../../data/group-data-access.js';
import { getChannels, createChannel, updateChannelName, addChannelMember, removeChannelMember } from '../../data/channel-data-access.js';
import { getAccount } from '../../data/account-data-access.js';

const Chats = (props) => {
    const { user, isAuthenticated, isAuthLoading } = useAuth0();
    const [searchParams, setSearchParams] = useSearchParams();
    const [connection, setConnection] = useState();
    const [channels, setChannels] = useState();
    const [selectedChannel, setSelectedChannel] = useState();
    const [account, setAccount] = useState();
    const [refreshNeeded, setRefreshNeeded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const load = async () => {
            var loadedAccount = await getUser();

            if (!loadedAccount)
                return;

            await configSocket(loadedAccount.Id);
            await loadChannels(loadedAccount.Id);
        }

        load();
    }, [user]);

    useEffect(() => {
        const refresh = async () => {
            var loadedChannels = await getChannels(account.Id);

            if (selectedChannel) {
                var freshIdx = loadedChannels.findIndex((channel) => channel.Id === selectedChannel.Id);

                //we need to be searching the trip channels if we can't find it in the main channels
                if (freshIdx === -1) {
                    var mainChannel = loadedChannels.find((channel) => channel.GroupId === selectedChannel.GroupId);
                    console.log(mainChannel)

                    if (mainChannel && mainChannel.TripChannels && mainChannel.TripChannels.length > 0) {
                        var subChannel = mainChannel.TripChannels.find((channel) => channel.Id === selectedChannel.Id);
                        setSelectedChannel(subChannel);
                        console.log(subChannel)
                        return;
                    }
                }

                setSelectedChannel(loadedChannels[freshIdx]);
            }

            setChannels(loadedChannels);
            setRefreshNeeded(false);
        }

        if (!account || !account?.Id || !refreshNeeded)
            return;

        refresh();
    }, [refreshNeeded]);

    useEffect(() => {
        if (channels)
            setIsLoading(false);

    }, [channels])

    useEffect(() => {
        if (!selectedChannel)
            return;

        setSearchParams({
            channel: selectedChannel.Id
        })
    }, [selectedChannel])

    const configSocket = async (id) => {
        const socketConnection = new HubConnectionBuilder()
            .configureLogging(LogLevel.Debug)
            .withUrl("/chat", {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .withAutomaticReconnect()
            .build();
        await socketConnection.start();
        setConnection(socketConnection);

        socketConnection.onreconnecting(error => {
            console.assert(connection.state === HubConnectionState.Reconnecting);

            document.getElementById("messageInput").disabled = true;

            //need to do an error message here
            //const li = document.createElement("li");
            //li.textContent = `Connection lost due to error "${error}". Reconnecting.`;
            //document.getElementById("messageList").appendChild(li);
        });

        socketConnection.invoke("connected", id)

        socketConnection && socketConnection.on("message", receiveMessage);
    };

    const loadChannels = async (accountId) => {
        var res = await getChannels(accountId);

        if (!res)
            res = []
        console.log(res)
        setChannels(res);

        if (searchParams && (res?.length > 0 ?? false)) {
            var channelIdFromUrl = searchParams.get("channel");
            var idx = res.findIndex((channel) => channel.Id == channelIdFromUrl);
            setSelectedChannel(res[idx]);
        }
    }

    const getUser = async () => {
        if (!isAuthenticated || isAuthLoading)
            return;

        var res = await getAccount(user.email);

        setAccount(res);

        return res;
    }

    const sendMessage = (channelId, message, images) => {
        let msg = {
            AccountId: account.Id,
            ChannelId: channelId,
            Message: message,
            Images: images
        }

        console.log(msg);
        sendChat(msg)
        //connection && connection.invoke("message", msg)
    }

    const receiveMessage = (message) => {
        setRefreshNeeded(true);
    }

    const chatBody = () => {
        if (isLoading) {
            return (<Loading />)
        }
        else {
            return (
                <MessagesPanel onSendMessage={sendMessage}
                                channel={selectedChannel}
                                user={account}
                                setRefreshNeeded={setRefreshNeeded} />
            )
        }
    }

    return (
        <MainLayout chatSelected={true}
                    subMenuControls={() => (<ChannelListControls account={account}
                                                                 refresh={() => setRefreshNeeded(true)}/>)}
                    subMenuContent={(setMobileMenuOpen) => (<ChannelList channels={channels}
                                                        setSelectedChannel={setSelectedChannel}
                                                        selectedChannel={selectedChannel}
                                                        searchParams={searchParams}
                                                        setMobileMenuOpen={setMobileMenuOpen}/>)}>
            {
                chatBody()
            }
        </MainLayout>
    )
}


export default Chats



