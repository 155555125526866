import axios from 'axios';

async function getGroups(accountId) {
    var res = await axios.get("api/group/" + accountId);
    console.log(res);

    return res.data;
}

async function getGroupMemberStatus(groupId, accountId) {
    try {
        var res = await axios.get("api/group/" + groupId + "/member/status?accountId=" + accountId);

        console.log(res)

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return null;
    }
}

async function createGroup(accountId, groupName) {
    var res = await axios.post("api/group/",
        {
            AccountId: accountId,
            GroupName: groupName
        })
    console.log(res);

    return res.data;
}

async function addGroupMember(accountId, groupId) {
    var res = await axios.put("api/group/" + groupId + "/member",
        {
            AccountId: accountId
        })
    console.log(res);

    return res.data;
}

async function updateGroupMemberStatus(accountId, groupId, status) {
    try {
        var res = await axios.put("api/group/" + groupId + "/member/status" + "?status=" + status + "&accountId=" + accountId, null)
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return null;
    }
}

async function removeGroupMember(accountId, groupId) {
    var res = await axios.delete("api/group/" + groupId + "/member/" + accountId)
    console.log(res);

    return res.data;
}

export { getGroups, getGroupMemberStatus, createGroup, addGroupMember, updateGroupMemberStatus, removeGroupMember }