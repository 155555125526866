import React, { useState, useEffect } from "react";
import './style.css';

import NotificationPopupItem from './notification-popup-item.js';

import Switch from '../Switch/switch.js';

import { getAccountNotifications } from '../../data/notification-data-access.js';

const NotifcationPopupContent = (props) => {
    const [notifications, setNotifications] = useState(props.notifications)
    const [showUnreadOnly, setShowUnreadOnly] = useState(true);

    useEffect(() => {
        const load = async () => {
            var res = await reloadNotifications();
        }

        load();
    }, [showUnreadOnly]);

    //TODO We need to do a loading spinner while this is loading, I don't have one anywhere yet IIRC
    const loadNotifications = async (accountId, read) => {
        setNotifications(null);
        var res = await getAccountNotifications(accountId, read);
        setNotifications(res);

        return res;
    }

    const reloadNotifications = async () => {
        await loadNotifications(props.account.Id, showUnreadOnly ? false : null);
    }

    return (
        <div className="notification-popup-content-container">
            <div className="notification-popup-content-header">
                <h2>Notifications</h2>
                <div className="notification-popup-content-controls">
                    <span>
                        Only Show Unread
                    </span>
                    <Switch value={showUnreadOnly}
                            onClick={setShowUnreadOnly} />
                </div>
            </div>
            <div className="notification-popup-content-body">
                    {
                        (!notifications || notifications?.length === 0) &&
                        <span className="no-content-message">There are no notifications to show</span>
                    }
                    {
                        notifications && notifications?.length > 0 &&
                    notifications.map((c, idx) => <NotificationPopupItem key={idx}
                        account={props.account}
                        notification={c}
                        reloadNotifications={reloadNotifications} />)
                    }
            </div>
        </div>
    )
}

export default NotifcationPopupContent