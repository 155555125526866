import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Calendar, Views, momentLocalizer, DateLocalizer } from 'react-big-calendar'
import moment from 'moment'

import Popup from '../../components/Popup/popup.js';
import AddItineraryItemPopupContent from './add-itinerary-item-popup-content.js';
import CreateItineraryPopupContent from './create-itinerary-popup-content.js';
import ItineraryEvent from './itinerary-event.js';
import AgendaEvent from './agenda-event.js';
import SettingsIcon from '../Icons/settings.js';
import 'react-big-calendar/lib/sass/styles.scss';
import './style.css'

import { getItineraryById, deleteItinerary } from '../../data/itinerary-data-access';
import ItineraryItemCard from './itinerary-item-card.js';
import TripMemberControl from '../Trip/trip-member-control.js';
import CreateTripControl from '../Trip/create-trip.js';
import SuggestEventButton from '../Trip/suggest-event-button.js';
import SuggestEventControl from '../Trip/suggest-event.js';

const ItineraryCalendar = (props) => {
    const localizer = momentLocalizer(moment)
    const [itinerary, setItinerary] = useState();
    const [addNewItineraryItemOpen, setAddNewItineraryItemOpen] = useState(false);
    const [selectedStartEndDateRange, setSelectedStartEndDateRange] = useState();
    const [addNewItineraryOpen, setAddNewItineraryOpen] = useState(false);
    const [settingsPopupOpen, setSettingsPopupOpen] = useState(false);
    const [selectedEventPopupOpen, setSelectedEventPopupOpen] = useState(false);

    const [selectedEvent, setSelectedEvent] = useState();

    const { defaultDate, scrollToTime, formats, components } = useMemo(
        () => ({
            defaultDate: itinerary?.StartDate ?? new Date(),
            scrollToTime: new Date(1970, 1, 1, 6),
            formats: {
                timeGutterFormat: (date, culture, localizer) =>
                    localizer.format(date, 'h:mm a', culture),
                dayFormat: (date, culture, localizer) =>
                    localizer.format(date, 'ddd', culture),
            },
            components: {
                agenda: {
                    event: AgendaEvent,
                },
                event: ItineraryEvent,
            }
        }),
        []
    )

    useEffect(() => {
        loadItinerary();
    }, [props.itinerary]);

    useEffect(() => {
        if (!addNewItineraryItemOpen)
            setSelectedStartEndDateRange();

    }, [addNewItineraryItemOpen])

    const loadItinerary = async () => {
        if (props.itinerary?.Id === null)
            return;

        var res = await getItineraryById(props.itinerary?.Id)

        if (!res)
            return;

        setItinerary(res);
    }

    const handleSelectSlot = useCallback(
        ({ start, end }) => {
            setSelectedStartEndDateRange({
                StartDate: start,
                EndDate: end
            })

            //if there is no itinerary selected, prompt the user to create a new itinerary and then create the item on that itinerary
            //this could definitely be streamlined
            if (!itinerary) {
                setAddNewItineraryOpen(true);
                return;
            }

            setAddNewItineraryItemOpen(true);
        },
        [itinerary]
    )

    const handleSelectEvent = useCallback(
        (event) => {
            setSelectedEvent(event);
            setSelectedEventPopupOpen(true);
        },
        []
    )

    var events = [];

    if (itinerary?.Items && itinerary?.Items.length > 0) {
        events = itinerary.Items.map((item, index) => ({
            id: item.Id,
            title: item.Name,
            start: new Date(item.StartDate),
            end: new Date(item.EndDate),
            images: item.Images.map((image, index) => ({
                content: image.Content,
                contentType: image.ContentType
            })),
            attributes: item.Attributes ?? []
        }));
    }

    return (
        <div className="itinerary-calendar-container">
            <div className="itinerary-header-controls-container">
                <TripMemberControl account={props.account}
                                      channel={{
                                          Id: props.itinerary.ChannelId
                                      }}
                                      refresh={props.loadItineraries}
                                      canOpen={() => true} />
                <Popup trigger={settingsPopupOpen}
                       setTrigger={setSettingsPopupOpen}
                       className="itineraries-list-add"
                       contentClassName="add-new-itinerary-popup-content"
                       showCloseButton={true}>
                    <SettingsPopupContent closePopup={() => setSettingsPopupOpen(false)}
                                          itinerary={props.itinerary}
                                          account={props.account}
                                          reloadItineraries={props.loadItineraries} />
                </Popup>
                <SettingsIcon onClick={() => setSettingsPopupOpen(!settingsPopupOpen)} />
            </div>
            <Calendar
                defaultDate={defaultDate}
                defaultView={Views.WEEK}
                localizer={localizer}
                events={events}
                style={{ height: 700 }}
                selectable
                onSelectEvent={handleSelectEvent}
                onSelectSlot={handleSelectSlot}
                dayLayoutAlgorithm="no-overlap"
                defaultView={Views.WEEK}
                formats={formats}
                components={components}
            />
            <Popup trigger={selectedEventPopupOpen}
                setTrigger={setSelectedEventPopupOpen}
                className="itineraries-list-add"
                contentClassName="add-new-itinerary-popup-content"
                showCloseButton={true}>
                <ItineraryItemCard closePopup={() => setSelectedEventPopupOpen(false)}
                                   item={selectedEvent}/>
            </Popup>
            <Popup trigger={addNewItineraryOpen}
                setTrigger={setAddNewItineraryOpen}
                className="itineraries-list-add"
                contentClassName="add-new-itinerary-popup-content"
                showCloseButton={true}>
                <CreateTripControl channel={null}
                                   accountId={props.account?.Id}
                                   refresh={loadItinerary}/>
            </Popup>
            <Popup trigger={addNewItineraryItemOpen}
                setTrigger={setAddNewItineraryItemOpen}
                className="itineraries-list-add"
                contentClassName="add-new-itinerary-popup-content"
                showCloseButton={true}>
                <SuggestEventControl itineraryId={itinerary?.Id}
                    startEndDateRange={selectedStartEndDateRange}
                    accountId={props.account?.Id}
                    refresh={() => {
                        loadItinerary()
                        setAddNewItineraryItemOpen(false)
                    }} />

            </Popup>
        </div>
    )
}

const SettingsPopupContent = (props) => {

    const _deleteItinerary = () => {
        var res = deleteItinerary(props.itinerary.Id, props.account.Id);

        props.reloadItineraries(props.account.Id);
        props.closePopup();
    }

    return (
        <div className="itinerary-settings-container">
            <span> Settings </span>
            <button className="button destructive-button" onClick={() => _deleteItinerary() }> Delete </button>
        </div>
    )
}

export default ItineraryCalendar;