import React, { useState, Component } from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import './style.css'

import ProfileSearchResults from './profile-search-results.js';

const ProfileSearchBar = (props) => {
    const [search, setSearch] = useState(props.originalSearch ?? "");
    const [suggestionsHidden, setSuggestionsHidden] = useState(true);
    const navigate = useNavigate();

    return (
        <div className="search-container">
            <div className="search-bar-container">
                <input type="text"
                    placeholder={props.placeholder}
                    className="search-bar-input"
                    value={search}
                    onChange={(event) => {
                        setSearch(event.target.value);
                    }}
                    onFocus={(event) => {
                        setSuggestionsHidden(false);
                    }}
                    onBlur={(event) => {
                        setSuggestionsHidden(true);
                    }}
                />
                <button disabled={false}
                    className="search-button button"
                    onClick={() => navigate(`/profile/search?search=${search}`) }>
                    Search
                </button>
            </div>
            <span className="search-input-validation-errors">{props.errors}</span>
            {
                !suggestionsHidden && !(search === '' || search === null) &&
                <div className="suggestion-results">
                    <ProfileSearchResults search={search} />
                </div>
            }
        </div>
    )
}

export default ProfileSearchBar
