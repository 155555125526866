import moment from 'moment';


export function countDownToDate(date) {
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;

    const distance = moment(date) - new Date();

    var weeks = Math.floor(distance / week);
    var days = Math.floor((distance % week) / day);
    var hours = Math.floor((distance % day) / hour);
    var minutes = Math.floor((distance % hour) / minute);
    var seconds = Math.floor((distance % minute) / second);

    const pluralOrNonPlural = (value) => {
        return value > 1
            ? 's'
            : '';
    }

    const getValueDescriptor = (value, label) => {
        return value > 0
            ? value + ' ' + label + pluralOrNonPlural(value)
            : '';
    }

    //idk maybe limit this to just the first three descriptors /
    // do we want seconds?
    const getFullString = (values) => {
        var finalString = '';

        const realValues = values.filter((value) => value !== '');

        if (realValues.length === 1)
            return realValues[0];

        if (realValues.length === 2)
            return realValues[0] + ' ' + realValues[1];

        for (let i = 0; i < realValues.length; i++) {
            if (i < realValues.length - 1)
                finalString += realValues[i] + ', ';

            if (i === realValues.length - 1)
                finalString += 'and ' + realValues[i];
        }

        return finalString;
    }

    return getFullString(
        [
            getValueDescriptor(weeks, 'week'),
            getValueDescriptor(days, 'day'),
            getValueDescriptor(hours, 'hour'),
            getValueDescriptor(minutes, 'minute')
        ]
    );
}
