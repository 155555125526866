import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment'
import { useSearchParams } from "react-router-dom";

import EditIcon from '../../components/Icons/edit.js';
import SettingsIcon from '../../components/Icons/settings.js';
import CheckIcon from '../../components/Icons/check.js';
import CloseIcon from '../../components/Icons/close.js';
import CloseCircleIcon from '../../components/Icons/close-circle.js';
import MainLayout from '../../components/MainLayout/main-layout.js';
import Popup from '../../components/Popup/popup.js';
import Loading from '../../components/Loading/loading.js';
import { isNullOrWhitespace } from '../../components/Utility/string-util.js';
import Base64Image, { getBase64 } from '../../components/Utility/base-64-image.js';
import { countDownToDate } from '../../components/Utility/date-util.js';

import './style.css'

import { getAccount } from '../../data/account-data-access.js';
import { getVotesByAccount, getVotesByGroupId, getPollById, createVote, castVote, updatePoll, removePoll, removeVotingOptionAttribute, resetAccountVote, resetPollVotes, finishPoll } from '../../data/vote-data-access';
import { getGroups, createGroup, addGroupMember, removeGroupMember } from '../../data/group-data-access';

const Votes = (props) => {
    const { user, isAuthenticated, isAuthLoading } = useAuth0();
    const [searchParams, setSearchParams] = useSearchParams();
    const [polls, setPolls] = useState(null);
    const [selectedPoll, setSelectedPoll] = useState();
    const [account, setAccount] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const load = async () => {
            var loadedAccount = await getUser();

            if (!loadedAccount)
                return;

            await loadPolls(loadedAccount.Id);
        }

        load();
    }, [user]);

    useEffect(() => {
        if (polls)
            setIsLoading(false);

    }, [polls])

    useEffect(() => {
        if (!selectedPoll)
            return;

        setSearchParams({
            poll: selectedPoll.Id
        })
    }, [selectedPoll])

    const loadPolls = async (accountId) => {
        setPolls(null);
        var res = await getVotesByAccount(accountId);
        setPolls(res);

        if (searchParams) {
            var pollIdFromUrl = searchParams.get("poll");
            var idx = res.findIndex((poll) => poll.Id == pollIdFromUrl);
            setSelectedPoll(res[idx]);
        }
    }

    const getUser = async () => {
        if (!isAuthenticated || isAuthLoading)
            return;

        var res = await getAccount(user.email);

        setAccount(res);

        return res;
    }

    var votesContent = () => {
        if (isLoading) {
            return <Loading />
        }
        else {
            return (
                <div className="votes-container3">
                    <VotesBody account={account}
                        poll={selectedPoll}
                        loadPolls={loadPolls}/>
                </div>
            )
        }
    }

    return (
        <MainLayout votesSelected={true}
                    subMenuControls={() => (<VoteListControls account={account} loadPolls={loadPolls} />)}
                    subMenuContent={() => (<VoteList polls={polls}
                                                     setSelectedPoll={setSelectedPoll}
                                                     selectedPoll={selectedPoll}
                                                     account={account}
                                                     loadPolls={loadPolls} />)}>
            {
                votesContent()
            }
        </MainLayout>
    )
}

const VoteList = (props) => {
    const [polls, setPolls] = useState(props.polls);

    useEffect(() => {
        setPolls(props.polls);
    }, [props.polls]);

    useEffect(() => {
        if (props.selectedPoll || !props.polls || props.polls.length === 0)
            return;

        props.setSelectedPoll(props.polls[0])
    })

    var hasVotes = polls && polls.length > 0;

    return (
        <div className="vote-list-container">
            <div className="vote-list-body">
                {
                    !hasVotes &&
                    <span className="no-content-message">There are no votes to show</span>
                }
                {
                    hasVotes &&
                    polls.map((c, idx) => <VoteListItem key={idx}
                        poll={c}
                        selectedPoll={props.selectedPoll}
                        setSelectedPoll={(poll) => props.setSelectedPoll(poll)} />)
                }
            </div>
        </div>
    )
}

const VoteListItem = (props) => {
    const isSelected = props.selectedPoll?.Id === props.poll.Id;

    var voteName = props.poll?.Name ?? "New Vote";

    if (voteName > 50)
        voteName = voteName.slice(0, 50) + "...";

    var groupName = props.poll?.Group?.GroupName ?? "";

    if (groupName > 50)
        groupName = groupName.slice(0, 50) + "...";

    return (
        <div className="vote-list-item-container"
            style={{ backgroundColor: isSelected ? "var(--dl-color-gray-700)" : "unset" }}
            onClick={() => props.setSelectedPoll(props.poll)}>
            <div className="vote-list-item-container1">
                <div className="vote-list-item-container2">
                    <div>
                        <h1 className="vote-list-item-name">
                            {voteName}
                        </h1>
                        <h3 className="vote-list-item-group-name">
                            {groupName}
                        </h3>
                    </div>

                    <svg viewBox="0 0 1024 1024"
                        className="vote-list-item-arrow-icon">
                        <path d="M250 176l92-90 426 426-426 426-92-90 338-336z"></path>
                    </svg>
                </div>

            </div>
        </div>
    )
}

const VoteListControls = (props) => {
    const [addNewVoteOpen, setAddNewVoteOpen] = useState(false);

    return (
        <div>
            <Popup trigger={addNewVoteOpen}
                   setTrigger={setAddNewVoteOpen}
                   className="vote-list-add"
                   contentClassName="add-new-vote-popup-content"
                   showCloseButton={true}>
                    <CreateVotePopupContent accountId={props.account?.Id} loadPolls={props.loadPolls} />
            </Popup>

            <div className="vote-list-header-controls">
                <div className="add-new-vote-button-container">
                    <svg viewBox="0 0 804.5714285714286 1024"
                        className="vote-add-poll-option"
                        onClick={() => setAddNewVoteOpen(true)}>
                        <path d="M804.571 420.571v109.714c0 30.286-24.571 54.857-54.857 54.857h-237.714v237.714c0 30.286-24.571 54.857-54.857 54.857h-109.714c-30.286 0-54.857-24.571-54.857-54.857v-237.714h-237.714c-30.286 0-54.857-24.571-54.857-54.857v-109.714c0-30.286 24.571-54.857 54.857-54.857h237.714v-237.714c0-30.286 24.571-54.857 54.857-54.857h109.714c30.286 0 54.857 24.571 54.857 54.857v237.714h237.714c30.286 0 54.857 24.571 54.857 54.857z"></path>
                    </svg>
                </div>
            </div>
        </div>

    )
}

const CreateVotePopupContent = (props) => {
    const [voteName, setVoteName] = useState("");
    const [expiresOn, setExpiresOn] = useState(new Date());
    const [selectedGroupId, setSelectedGroupId] = useState(-1);
    const [userGroups, setUserGroups] = useState([]);
    const [newGroupMembers, setNewGroupMembers] = useState([]);
    const [newGroupMember, setNewGroupMember] = useState("");

    useEffect(() => {
        const loadUserGroups = async () => {
            var res = await getGroups(props.accountId);

            setUserGroups(res);
        }
        loadUserGroups();
    }, [props.accountId]);

    const handleInput = e => {
        if (e.target.value === '\n') {
            return;
        }

        setNewGroupMember(e.target.value);
    }

    const addOnEnter = e => {
        if (e.key === 'Enter') {
            setNewGroupMembers([...newGroupMembers, newGroupMember])
            setNewGroupMember("");
        }
    }

    const create = async () => {
        //need to show a "vote name must be set or something stupid"
        if (voteName?.trim().length === 0)
            return;

        var groupId = selectedGroupId == -1
            ? null
            : selectedGroupId;

        var request = {
            GroupId: groupId,
            CreatedByAccountId: props.accountId,
            Name: voteName,
            NewGroupMembers: newGroupMembers
        }

        var result = await createVote(request);

        //need to show something to the user here
        if (!result)
            return;

        await props.loadPolls(props.accountId);
    }

    var userGroupOptions = "";
    if (userGroups.length > 0) {
        userGroupOptions = userGroups.map((userGroup, index) =>
            <option value={`${userGroup.Id}`}>{userGroup.GroupName}</option>
        );
    }

    var newGroupMembersList = "";
    if (newGroupMembers.length > 0) {
        newGroupMembersList = newGroupMembers.map((newGroupMember, index) =>
            <NewGroupMemberName name={newGroupMember} key={index} />
        );
    }

    return (
        <div className="create-vote-popup">
            <p>Create new Vote</p>

            <input
                type="text"
                placeholder="Name..."
                className="create-vote-popup-input"
                onChange={(event) => {
                    setVoteName(event.target.value)
                }}
                value={voteName}
            />

            <label className="create-vote-popup-input-label">
                Vote Expiration:
                <input type="date"
                    value={expiresOn}
                    className="create-vote-popup-input"
                    onChange={e => setExpiresOn(moment(new Date(e.target.value)).format('YYYY-MM-DD'))} />
            </label>

            <label className="create-vote-popup-input-label">
                Group:
                <select className="create-vote-popup-group-select"
                    onChange={(event) => {
                        setSelectedGroupId(parseInt(event.target.value))
                    }}
                    defaultValue="Create new group">
                    <option value="-1">Create new group</option>
                    {
                        userGroupOptions
                    }
                </select>
            </label>

            {selectedGroupId == -1 &&
                <div className="create-vote-popup-new-group-container">
                    <label className="create-vote-popup-input-label">
                        Invite your friends:
                        <input type="text"
                            placeholder="Email or name..."
                            className="create-vote-popup-input"
                            value={newGroupMember}
                            onChange={handleInput}
                            onKeyDown={addOnEnter}
                        />
                    </label>

                    <div className="create-vote-popup-new-group-members-container">
                        {
                            newGroupMembersList
                        }
                    </div>
                </div>
            }
            <button className="create-vote-popup-button button"
                onClick={() => create()}>
                <span>Create</span>
            </button>
        </div>
    )
}

const VotesHeader = (props) => {
    const [pollOptionsOpen, setPollOptionsOpen] = useState(false);

    const reloadPolls = async () => {
        await props.loadPolls(props.account.Id);
    }

    return (
        <div className="vote-poll-header">
            <div className="vote-poll-tools">
                <Popup trigger={pollOptionsOpen}
                    setTrigger={setPollOptionsOpen}
                    className="vote-list-add"
                    contentClassName="add-new-vote-popup-content"
                    showCloseButton={true}>
                    <VoteOptionsMenu poll={props.poll} reloadPolls={reloadPolls} loadPoll={props.loadPoll} account={props.account} setPollOptionsOpen={setPollOptionsOpen}/>
                </Popup>

                <div className="vote-poll-name-container">
                    <h1 className="vote-poll-name1">
                        <span>{props.poll?.Name}</span>

                        {
                            props.poll?.Complete
                            && <span>&nbsp; &middot; &nbsp;Complete</span>
                        }

                        {
                            !props.poll?.Complete
                            && <span>&nbsp; &middot; &nbsp;active </span>
                        }
                    </h1>
                </div>
                <div className="vote-poll-tools-container">
                    <div onClick={() => setPollOptionsOpen(true)}>
                        <SettingsIcon/>
                    </div>
                </div>
            </div>

            {
                !props.poll?.Complete &&
                props.poll.ExpiresOn != null
                && <span>voting ends in: {countDownToDate(props.poll.ExpiresOn)} </span>
            }
        </div>

    )
}

const VoteOptionsMenu = (props) => {
    const resetAllVotes = async () => {
        await resetPollVotes(props.poll.Id)
        await props.reloadPolls();
    }

    const deleteThisPoll = async () => {
        await removePoll(props.poll.Id, props.account.Id)
        await props.reloadPolls();
        props.setPollOptionsOpen(false);
    }

    const endVoting = async () => {
        await finishPoll(props.poll.Id)
        await props.reloadPolls();
        props.setPollOptionsOpen(false);
    }

    return (
        <div className="create-vote-popup">
            <p>Options:</p>
            <ChangePollName poll={props.poll} reloadPolls={props.reloadPolls} setPollOptionsOpen={props.setPollOptionsOpen} />
            <ChangePollExpirationDate poll={props.poll} reloadPolls={props.reloadPolls} setPollOptionsOpen={props.setPollOptionsOpen} />
            <button className="reset-votes-button button" onClick={resetAllVotes}>Reset All Votes</button>
            <button className="reset-votes-button button" onClick={deleteThisPoll}>Delete Poll</button>
            <button className="complete-poll-button button" onClick={endVoting}>End Voting</button>
        </div>
    );
}

const ChangePollName = (props) => {
    const [pollName, setPollName] = useState(props.poll?.Name);

    const savePollName = async () => {
        if (pollName === props.poll?.Name) {
            return;
        }

        var request = {
            ...props.poll,
            Name: pollName
        }

        var result = await updatePoll(request);

        //need to show something to the user here
        if (!result)
            return;

        await props.reloadPolls();
        props.setPollOptionsOpen(false);
    }

    const reset = () => {
        setPollName(props.poll?.Name);
    }

    return (
        <div className="change-poll-name-container">

            <span className="poll-options-property-label">Name:</span>
            <input
                type="text"
                placeholder="Name..."
                className="poll-options-property-value"
                onChange={(event) => {
                    setPollName(event.target.value)
                }}
                value={pollName}
            />

            <div className="poll-options-property-controls">
                <button className="vote-attribute-upsert-container-controls-button"
                    onMouseDown={savePollName}>
                    <CheckIcon className="extra-small-2" />
                </button>
                <button className="vote-attribute-upsert-container-controls-button"
                    onMouseDown={reset}>
                    <CloseIcon className="extra-small-2" />
                </button>
            </div>
        </div>
    )
}

const ChangePollExpirationDate = (props) => {
    const [expirationDate, setExpirationDate] = useState(props.poll?.ExpiresOn ?? '');

    const savePollExpirationDate = async () => {
        if (expirationDate === props.poll?.ExpiresOn) {
            return;
        }

        var request = {
            ...props.poll,
            ExpiresOn: expirationDate
        }

        var result = await updatePoll(request);

        //need to show something to the user here
        if (!result)
            return;

        await props.reloadPolls();
        props.setPollOptionsOpen(false);
    }

    const reset = () => {
        setExpirationDate(props.poll?.ExpiresOn);
    }

    return (
        <div className="change-poll-name-container">

            <span className="poll-options-property-label">Expires On:</span>
            <input
                type="datetime-local"
                className="poll-options-property-value"
                onChange={(event) => {
                    setExpirationDate(event.target.value)
                }}
                value={expirationDate}
            />

            <div className="poll-options-property-controls">
                <button className="vote-attribute-upsert-container-controls-button"
                    onMouseDown={savePollExpirationDate}>
                    <CheckIcon className="extra-small-2" />
                </button>
                <button className="vote-attribute-upsert-container-controls-button"
                    onMouseDown={reset}>
                    <CloseIcon className="extra-small-2" />
                </button>
            </div>
        </div>
    )
}

const VotesBody = (props) => {
    const [poll, setPoll] = useState(props.poll);

    var content = <NoPollSelectedContent />;

    useEffect(() => {
        loadPoll();
    }, [props.poll]);

    const loadPoll = async () => {
        if (props.poll?.Id === undefined || props.poll?.Id === null)
            return;

        var res = await getPollById(props.poll?.Id)

        if (!res)
            return;

        setPoll(res);
    }

    if (poll)
    {
        var pollIsComplete = poll.Complete ?? (poll.TotalVotesCast >= poll.TotalVotes && poll.TotalVotesCast !== 0);

        const userHasSubmittedVote = () => {
            return poll.Votes?.some((vote) => vote.CreatedBy === props.account.Id) ?? false;
        }

        content = pollIsComplete || userHasSubmittedVote()
            ? <PollResultsContent account={props.account} poll={poll} pollIsComplete={pollIsComplete} reloadPoll={loadPoll} loadPolls={props.loadPolls} />
            : <ActiveVoteContent account={props.account} poll={poll} loadPolls={props.loadPolls} />
    }

    return (
        <div className="vote-poll-content">
            <VotesHeader account={props.account} poll={poll} loadPolls={props.loadPolls} loadPoll={loadPoll} />
            {
                content
            }
        </div>
    )
}

//if we go down the path of only allowing the vote creator to start the vote
const StartVotePage = (props) => {
    return (
        <div className="vote-poll-content">
        </div>
    );
}
const VoteNotStartedPage = (props) => {
    return (
        <div className="vote-poll-content">
        </div>
    );
}

const NoPollSelectedContent = (props) => {
    return (
        <div className="vote-poll-content">
        </div>
    );
}

//We will need to have an end vote button somewhere
const ActiveVoteContent = (props) => {
    const [newVoteOptionOpen, setNewVoteOptionOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState();

    const hasVotingOptions = props.poll?.VotingOptions && props.poll?.VotingOptions?.length > 0;
    const voteSubmitEnabled = selectedOption && selectedOption.Id >= 0;
    const voteSubmitClass = voteSubmitEnabled
        ? "vote-submit"
        : ".vote-submit .disabled";

    const submit = async () => {
        if (!voteSubmitEnabled)
            return;

        var request = {
            CreatedBy: props.account?.Id,
            SelectedOptionId: selectedOption?.Id
        }

        var res = await castVote(request, props.poll?.Id)

        if (!res)
            return;

        await props.loadPolls(props.account?.Id);
    }

    return (
        <div>
            <Popup trigger={newVoteOptionOpen}
                setTrigger={setNewVoteOptionOpen}
                className="vote-list-add"
                contentClassName="add-new-vote-popup-content"
                showCloseButton={true}>
                <UpsertVotingOptionPopupContent accountId={props.account?.Id}
                    poll={props.poll}
                    loadPolls={props.loadPolls}
                    setOpen={setNewVoteOptionOpen} />
            </Popup>

            {
                !hasVotingOptions &&
                <span className="no-content-message">There are no voting options to show</span>
            }

            <div className="vote-poll-options">
                {
                    hasVotingOptions &&
                    props.poll?.VotingOptions?.map((c, idx) => <VoteOptionCard key={idx}
                                                                               option={c}
                                                                               selectedOption={selectedOption}
                                                                               setSelectedOption={setSelectedOption}
                                                                               loadPolls={props.loadPolls}
                                                                               poll={props.poll}
                                                                               accountId={props.account?.Id}/>)
                }
                <svg
                    viewBox="0 0 1024 1024"
                    className="vote-add-poll-option1"
                    onClick={() => setNewVoteOptionOpen(true)}>
                    <path d="M213.333 554.667h256v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256h256c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-256v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v256h-256c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
                </svg>
            </div>
            {
                hasVotingOptions &&
                <div className="vote-poll-decision-container">
                    <div className={voteSubmitClass}
                        onClick={submit}>
                        <h3 className="vote-submit-text">Submit</h3>
                    </div>
                    <div className="vote-abstain">
                        <h3 className="vote-abstain-text">Abstain</h3>
                    </div>
                </div>
            }
        </div>
    )
}

const VoteOptionCard = (props) => {
    const className = props.selectedOption?.Id === props.option?.Id
        ? "vote-poll-option-selected"
        : "vote-poll-option";
    const [upsertVoteOptionOpen, setUpsertVoteOptionOpen] = useState(false);

    //will need to read the attributes at some point
    //need to get the default image from something other than that list

    if (!props.option)
        return;

    const attributes = props.option.Attributes.filter((attribute) => attribute.Label !== 'description')
    const descriptionAttribute = getDescriptionFromAttributes(props.option?.Attributes)

    return (
        <div>
            <Popup trigger={upsertVoteOptionOpen}
                setTrigger={setUpsertVoteOptionOpen}
                className="vote-list-add"
                contentClassName="add-new-vote-popup-content"
                showCloseButton={true}>
                <UpsertVotingOptionPopupContent accountId={props.accountId}
                    poll={props.poll}
                    loadPolls={props.loadPolls}
                    setOpen={setUpsertVoteOptionOpen}
                    option={props.option}
                />
            </Popup>
            <div onClick={() => setUpsertVoteOptionOpen(true)}>
                <EditIcon className="voting-option-edit-icon extra-small-2" />
            </div>
            <div className={className}
                 onClick={() => props.setSelectedOption(props.option)}>
                
                {
                    props.option.Images.length > 0
                        ? props.option.Images.map((image, id) =>
                            <Base64Image
                                alt={""}
                                image={image.Content}
                                contentType={image.ContentType}
                                className="voting-option-image"
                                key={id}
                            />
                        )
                        : <img alt="image"
                               src="https://play.teleporthq.io/static/svg/default-img.svg"
                               className="vote-image"
                           />
                }
                <h3>{props.option?.Name}</h3>
                <span>{descriptionAttribute?.Value}</span>
                {
                    attributes.map((attribute, index) =>
                        <div key={index}>
                            <span>{attribute.Label}:&nbsp;</span>
                            <span>{attribute.Value}</span>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

const UpsertVotingOptionPopupContent = (props) => {
    const [optionName, setOptionName] = useState(props.option?.Name ?? "");
    const [optionDescription, setOptionDescription] = useState(getDescriptionFromAttributes(props.option?.Attributes)?.Value ?? "");
    const [attributes, setAttributes] = useState(props.option?.Attributes ?? []);
    const [selectedImage, setSelectedImage] = useState(null);

    //we need a way to delete an attribute at some point
    const upsertAttribute = (attribute) => {
        console.log(attributes);

        if (attribute.Id) {
            const match = attributes.find((a) => a.Id === attribute.Id);
            match.Value = attribute.Value;
            match.Label = attribute.Label;
            setAttributes(attributes);
        }
        else {
            setAttributes([...attributes, attribute]);
        }
    }

    const create = async () => {
        //need to show a "vote name must be set or something stupid"
        if (optionName?.trim().length === 0)
            return;

        var images = [];
        if (selectedImage) {
            let dataUrl = await getBase64(selectedImage);

            let image = dataUrl.replace('data:', '').replace(/^.+,/, '');
            let contentType = new RegExp("(?:image\/).*(?=\;)").exec(dataUrl)[0]
            images.push({
                FileName: crypto.randomUUID(),
                CreatedBy: props.accountId,
                Content: image,
                ContentType: contentType
            })
        }

        const allAttributes = attributes ?? [];
        if (optionDescription.length > 0) {
            const existingAttribute = getDescriptionFromAttributes(allAttributes);
            if (existingAttribute) {
                existingAttribute.Value = optionDescription;
            }
            else {
                allAttributes.push({
                    Label: "description",
                    Value: optionDescription
                })
            }
        }

        var request = {
            ...props.poll,
            VotingOptions: [
                {
                    Id: props.option?.Id ?? null,
                    Name: optionName,
                    Attributes: allAttributes,
                    Images: images
                }
            ]
        }

        console.log(request);

        var result = await updatePoll(request);

        //need to show something to the user here
        if (!result)
            return;

        await props.loadPolls(props.accountId);
        props.setOpen(false);
    }

    const deleteThisOption = async () => {
        //this is a dirty way to do this. pictures, attributes, etc... will be orphaned
        var request = {
            ...props.poll,
            VotingOptions: [
                {
                    ...props.option,
                    DeletedOn: new Date().toJSON()
                }
            ]
        }

        console.log(request);

        var result = await updatePoll(request);

        await props.loadPolls(props.accountId);
        props.setOpen(false);
    }

    //Add in something like a drop down for the attributes at some point
    return (
        <div className="create-vote-popup">
            {
                props.option?.Id
                    ? <p>Update Option</p>
                    : <p>Create new Option</p>
            }
            <input
                type="file"
                name="image"
                onChange={(event) => {
                    console.log(event.target.files[0]);
                    setSelectedImage(event.target.files[0]);
                }}
            />

            <input
                type="text"
                placeholder="Name..."
                className="create-vote-popup-input"
                onChange={(event) => {
                    setOptionName(event.target.value)
                }}
                value={optionName}
            />

            <input
                type="text"
                placeholder="Description..."
                className="create-vote-popup-input"
                onChange={(event) => {
                    setOptionDescription(event.target.value)
                }}
                value={optionDescription}
            />

            <div className="vote-attribute-edit-container">
                <span>Additional Info:</span>
                <div className="vote-attribute-container">
                    {
                        attributes.map((attribute, index) =>
                            <VoteAttribute attribute={attribute} upsertAttribute={upsertAttribute} key={index} />
                        )
                    }
                </div>

                <VoteAttribute upsertAttribute={upsertAttribute} />
            </div>

            <button className="create-vote-popup-button button"
                onClick={() => create()}>
                <span>
                    {
                        props.option?.Id
                            ? "Update"
                            : "Create"
                    }
                </span>
            </button>
            {
                props.option?.Id &&
                <button className="reset-votes-button button" onClick={deleteThisOption}>Delete Option</button>
            }
        </div>
    )
}

const VoteAttribute = (props) => {
    const [attributeKey, setAttributeKey] = useState(props.attribute?.Label ?? "");
    const [attributeValue, setAttributeValue] = useState(props.attribute?.Value ?? "");

    if (attributeKey === 'description')
        return;

    const upsertAttribute = () => {
        //button should probably be disabled in this case
        if (attributeKey.length === 0
         || attributeValue.length === 0)
            return;

        props.upsertAttribute({
            Label: attributeKey,
            Value: attributeValue
        })

        reset();
    }

    const reset = () => {
        setAttributeKey(props.attribute?.Label ?? "")
        setAttributeValue(props.attribute?.Value ?? "")
    }

    return (
        <div className="vote-attribute-upsert-container">
            <input
                type="text"
                placeholder="Label..."
                className="vote-attribute-input"
                onChange={(event) => {
                    setAttributeKey(event.target.value)
                }}
                value={attributeKey}
            />

            <input
                type="text"
                placeholder="Value..."
                className="vote-attribute-input"
                onChange={(event) => {
                    setAttributeValue(event.target.value)
                }}
                value={attributeValue}
            />

            <div className="vote-attribute-upsert-container-controls">
                <button className="vote-attribute-upsert-container-controls-button"
                    onMouseDown={upsertAttribute}>
                    <CheckIcon className="extra-small-2" />
                </button>
                <button className="vote-attribute-upsert-container-controls-button"
                    onMouseDown={reset}>
                    <CloseIcon className="extra-small-2" />
                </button>
            </div>
        </div>
    )
}

const PollResultsContent = (props) => {
    var usersVote = props.poll?.Votes?.find((x) => x.CreatedBy === props.account.Id);
    var matchingOption = props.poll?.VotingOptions?.find((x) => x.Id === usersVote?.SelectedOptionId);
    var winningOptions = props.poll?.VotingOptions?.filter((x) => x.Rank === 1);

    const resetUserVote = async () => {
        await resetAccountVote(props.poll.Id, props.account.Id)
        await props.reloadPoll();
    }

    const hasVotingOptions = props.poll?.VotingOptions && props.poll?.VotingOptions?.length > 0;

    return (
        <div>
            <div className="vote-results-poll-results-container">
                {
                    winningOptions.length === 1 &&
                    <div className="vote-results-poll-winner">
                        <h2 className="vote-results-text1">
                            Winner:
                        </h2>
                        <VoteOptionCard option={winningOptions[0]}
                            selectedOption={null}
                            setSelectedOption={() => { }}
                            loadPolls={props.loadPolls}
                            poll={props.poll}
                            accountId={props.account?.Id} />
                    </div>
                }
                {
                    winningOptions.length > 1 &&
                    <div className="vote-results-poll-winner">
                        <h2 className="vote-results-text1">
                            Looks like we have a tie:
                        </h2>
                    </div>
                }
                {
                    matchingOption &&
                    <div className="vote-results-poll-winner">
                        <h2 className="vote-results-text1">
                            You voted for:
                        </h2>
                        <VoteOptionCard option={matchingOption}
                            selectedOption={null}
                            setSelectedOption={() => { }}
                            loadPolls={props.loadPolls}
                            poll={props.poll}
                            accountId={props.account?.Id} />
                    </div>
                }
                {
                    !matchingOption &&
                    <div className="vote-results-poll-winner">
                        <h2 className="vote-results-text1">
                            You didn't vote in this poll
                        </h2>
                    </div>
                }
                <div className="vote-results-vote-distributions">
                    {
                        hasVotingOptions &&
                        props.poll?.VotingOptions?.map((c, idx) => <VoteResultsListItem key={idx}
                            option={c}
                            poll={props.poll}
                            loadPolls={props.loadPolls}
                            poll={props.poll}
                            accountId={props.account?.Id}
                        />)
                    }
                </div>
            </div>
            <div className="vote-results-poll-decision-container">
                {
                    matchingOption && !props.pollIsComplete &&
                    <div className="vote-results-recast" onClick={resetUserVote}>
                        <h3 className="vote-results-recast-text">
                            Reset My Vote
                        </h3>
                    </div>
                }
            </div>
        </div>
    )
}

//maybe we add something that shows the rank, or a popup that will show more details?
const VoteResultsListItem = (props) => {
    const [optionDetailsOpen, setOptionDetailsOpen] = useState(false);
    const votesPercentage = props.option.VotesCastForOption / props.poll.TotalVotes;
    const resultDescription = `${+votesPercentage.toFixed(2)}: ${props.option.VotesCastForOption} / ${props.poll.TotalVotes} Votes`
    const optionDescription = getDescriptionFromAttributes(props.option?.Attributes)?.Value ?? "";

    return (
        <div className="vote-results-vote-results-wrapper2">
            <Popup trigger={optionDetailsOpen}
                setTrigger={setOptionDetailsOpen}
                className="vote-list-add"
                contentClassName="add-new-vote-popup-content"
                showCloseButton={true}>
                <VoteOptionDetails accountId={props.accountId}
                    option={props.option}
                />
            </Popup>
            <div className="vote-results-poll-option3" onClick={() => setOptionDetailsOpen(true)}>
                <h3>{props.option.Name}</h3>
                <span>{optionDescription}</span>
            </div>
            <div className="vote-results-vote-results-container2">
                <div className="vote-results-percentage-of-vote2">
                    <span className="vote-results-text4">
                        { resultDescription }
                    </span>
                </div>
                <div className="vote-results-vote-tally-container2">
                    <div className="vote-results-vote-tally4" style={{ width: `${votesPercentage * 100}%` } }></div>
                    <div className="vote-results-vote-tally5" style={{ width: `${(1 - votesPercentage) * 100}%` }}></div>
                </div>
            </div>
        </div>
    )
}

const VoteOptionDetails = (props) => {
    const attributes = props.option.Attributes.filter((attribute) => attribute.Label !== 'description')
    const descriptionAttribute = getDescriptionFromAttributes(props.option?.Attributes)

    return (
        <div className="create-vote-popup">
            {
                props.option.Images.length > 0
                    ? props.option.Images.map((image, id) =>
                        <Base64Image
                            alt={""}
                            image={image.Content}
                            contentType={image.ContentType}
                            className="voting-option-image"
                            key={id}
                        />
                    )
                    : <img alt="image"
                        src="https://play.teleporthq.io/static/svg/default-img.svg"
                        className="vote-image"
                    />
            }
            <h3>{props.option?.Name}</h3>
            <span>{descriptionAttribute?.Value}</span>
            {
                attributes.map((attribute, index) =>
                    <div key={index}>
                        <span>{attribute.Label}:&nbsp;</span>
                        <span>{attribute.Value}</span>
                    </div>
                )
            }
        </div>
    )
}

const NewGroupMemberName = (props) => {
    return (
        <div className="new-group-member-name-container">
            <p className="new-group-member-name">{props.name}</p>
            <CloseCircleIcon className="new-group-member-name-delete-icon" />
        </div>
    )
}

const getDescriptionFromAttributes = (attributes) => {
    return attributes?.find((attribute) => attribute.Label === "description")
}

export default Votes