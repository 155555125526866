import React from 'react';
import CompleteAccountInfo from '../../components/Account/complete-account-info.js';
import './style.css'

const AccountManagement = (props) => {
    return (
        <div className="account-management">
            <div className="account-management-page-body">
                <CompleteAccountInfo />
            </div>
        </div>
    )
}

export default AccountManagement