import React from 'react';
import MainLayout from '../../components/MainLayout/main-layout.js';
import WaitlistComponent from '../../components/Waitlist/waitlist.js';
import Footer from '../../components/Footer/footer.js';
import AccountManagementComponent from '../../components/Account/account-management.js';
import './style.css'

const AccountManagement = (props) => {
    return (
        <MainLayout>
            <AccountManagementComponent />
        </MainLayout>
    )
}

export default AccountManagement