import axios from 'axios';

async function getAccountFriends(accountId, status) {
    try {
        var res = await axios.get("api/Friend", {
            params: {
                accountId: accountId,
                status: status
            }
        });
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

async function getMutualFriends(accountId, secondAccountId) {
    try {
        var res = await axios.get("api/Friend/mutual", {
            params: {
                accountId: accountId,
                secondAccountId: secondAccountId
            }
        });
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

async function getFriendship(accountId, friendId) {
    try {
        var res = await axios.get("api/Friend/status", {
            params: {
                accountId: accountId,
                friendId: friendId
            }
        });
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

async function createFriendRequest(currentUser, requestedUser) {
    try {
        var res = await axios.post("api/Friend/Request",
            {
                ActiveUserId: currentUser,
                RequestedUserId: requestedUser
            });
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

async function approveFriendRequest(currentUser, requestedUser) {
    try {
        var res = await axios.put("api/Friend/Request/Approve", 
            {
                ActiveUserId: currentUser,
                RequestedUserId: requestedUser
            });

        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

async function rejectFriendRequest(currentUser, requestedUser) {
    try {
        var res = await axios.put("api/Friend/Request/Reject", 
            {
                ActiveUserId: currentUser,
                RequestedUserId: requestedUser
            });
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

async function removeFriend(currentUser, requestedUser) {
    try {
        var res = await axios.put("api/Friend/Remove", 
            {
                ActiveUserId: currentUser,
                RequestedUserId: requestedUser
            });
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return [];
    }
}

export { getAccountFriends, getMutualFriends, getFriendship, createFriendRequest, approveFriendRequest, rejectFriendRequest, removeFriend }