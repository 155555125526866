import React, { useState, useEffect } from 'react';

import './style.css'

import { createItinerary } from '../../data/itinerary-data-access';
import { getGroups } from '../../data/group-data-access';
import RoundedAttributeContainer from '../Controls/rounded-attribute-container.js';


//This is dead
const CreateItineraryPopupContent = (props) => {
    const [itineraryName, setItineraryName] = useState("");
    const [selectedGroupId, setSelectedGroupId] = useState(-1);
    const [userGroups, setUserGroups] = useState([]);
    const [newGroupMembers, setNewGroupMembers] = useState([]);
    const [newGroupMember, setNewGroupMember] = useState("");

    useEffect(() => {
        const loadUserGroups = async () => {
            var res = await getGroups(props.accountId);

            setUserGroups(res);
        }
        loadUserGroups();
    }, [props.accountId]);

    const handleInput = e => {
        if (e.target.value === '\n') {
            return;
        }

        setNewGroupMember(e.target.value);
    }

    const addOnEnter = e => {
        if (e.key === 'Enter') {
            setNewGroupMembers([...newGroupMembers, newGroupMember])
            setNewGroupMember("");
        }
    }

    const create = async () => {
        //need to show a "itinerary name must be set or something stupid"
        if (itineraryName?.trim().length === 0)
            return;

        var groupId = selectedGroupId == -1
            ? null
            : selectedGroupId;

        var request = {
            GroupId: groupId,
            CreatedByAccountId: props.accountId,
            Name: itineraryName,
            NewGroupMembers: newGroupMembers
        }

        var result = await createItinerary(request);

        //need to show something to the user here
        if (!result)
            return;

        await props.loadItineraries(props.accountId);
    }

    var userGroupOptions = "";
    if (userGroups.length > 0) {
        userGroupOptions = userGroups.map((userGroup, index) =>
            <option value={`${userGroup.Id}`} key={index}>{userGroup.GroupName}</option>
        );
    }

    var newGroupMembersList = "";
    if (newGroupMembers.length > 0) {
        newGroupMembersList = newGroupMembers.map((newGroupMember, index) =>
            <RoundedAttributeContainer name={newGroupMember} key={index} />
        );
    }

    return (
        <div className="create-itinerary-popup">
            <p>Create new Itinerary</p>

            <input
                type="text"
                placeholder="Name..."
                className="create-itinerary-popup-input"
                onChange={(event) => {
                    setItineraryName(event.target.value)
                }}
                value={itineraryName}
            />

            <label>
                Group:
            </label>

            <select className="create-itinerary-popup-group-select"
                onChange={(event) => {
                    setSelectedGroupId(parseInt(event.target.value))
                }}
                defaultValue="Create new group">
                <option value="-1">Create new group</option>
                {
                    userGroupOptions
                }
            </select>

            {selectedGroupId == -1 &&
                <div className="create-itinerary-popup-new-group-container">
                    <label>
                        Invite your friends:
                    </label>

                    <input type="text"
                        placeholder="Email or name..."
                        className="create-itinerary-popup-input"
                        value={newGroupMember}
                        onChange={handleInput}
                        onKeyDown={addOnEnter}
                    />

                    <div className="create-itinerary-popup-new-group-members-container">
                        {
                            newGroupMembersList
                        }
                    </div>
                </div>
            }
            <button className="create-itinerary-popup-button button"
                onClick={() => create()}>
                <span>Create</span>
            </button>
        </div>
    )
}

export default CreateItineraryPopupContent;