import axios from 'axios';

async function getChannels(accountId) {
    try { 
        var res = await axios.get("api/channel/" + accountId);

        console.log(res)

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return null;
    }
}

async function getChannelMembers(channelId) {
    try {
        var res = await axios.get("api/channel/" + channelId + "/members");

        console.log(res)

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return null;
    }
}

async function getChannelMemberStatus(channelId, accountId) {
    try {
        var res = await axios.get("api/channel/" + channelId + "/member/status?accountId=" + accountId);

        console.log(res)

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return null;
    }
}

async function createChannel(accountId, groupId, channelName, members) {
    var res = await axios.post("api/channel/",
        {
            CreatedByAccountId: accountId,
            GroupId: groupId,
            ChannelName: channelName,
            Members: members
        })
    console.log(res);

    return res.data;
}

async function updateChannelName(channelId, channelName) {
    console.log(channelName);
    const config = { headers: { 'Content-Type': 'application/json' } };

    var res = await axios.put("api/channel/" + channelId + "/name", channelName, config)
    console.log(res);

    return res.data;
}

async function addChannelMember(accountId, channelId) {
    try {
        var res = await axios.put("api/channel/" + channelId + "/member/" + accountId)
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return null;
    }
}

async function updateChannelMemberStatus(accountId, channelId, status) {
    try {
        var res = await axios.put("api/channel/" + channelId + "/member/status" + "?status=" + status + "&accountId=" + accountId, null)
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return null;
    }
}

async function removeChannelMember(accountId, channelId) {
    try {
        var res = await axios.delete("api/channel/" + channelId + "/member/" + accountId)
        console.log(res);

        return true;
    }
    catch (exception) {
        console.log(exception);
        return false;
    }
}

async function deleteChannel(channelId) {
    try {
        var res = await axios.delete("api/channel/" + channelId)
        console.log(res);

        return res.data;
    }
    catch (exception) {
        console.log(exception);
        return null;
    }
}

export { getChannels, getChannelMemberStatus, createChannel, updateChannelName, addChannelMember, updateChannelMemberStatus, removeChannelMember, deleteChannel, getChannelMembers }