import React, { useEffect, useState } from 'react';
import Base64Image from '../../components/Utility/base-64-image.js';

import './style.css'

const ChatImage = (props) => {
    return (
        <div>
            <div className="link-preview">
                <Base64Image
                    alt={""}
                    image={props.image.Content}
                    contentType={props.image.ContentType}
                    className="link-preview-image"
                />
            </div>
        </div>
    )
}

export default ChatImage
