import React, { useState, useRef } from 'react';
import { getBase64 } from '../Utility/base-64-image';

const ImageUploadButton = ({ label, accountId, setSelectedImage }) => {
    const [imageInputKey, setImageInputKey] = useState(0);
    const fileInput = useRef(null);

    const handleFileUploadClick = event => {
        fileInput.current.click();
    };

    const handleFileSelection = async (file) => {
        let dataUrl = await getBase64(file);

        let imageData = dataUrl.replace('data:', '').replace(/^.+,/, '');
        let contentType = new RegExp("(?:image\/).*(?=\;)").exec(dataUrl)[0]
        var image = {
            FileName: crypto.randomUUID(),
            CreatedBy: accountId,
            Content: imageData,
            ContentType: contentType
        }

        setSelectedImage(image);
        setImageInputKey(imageInputKey++);
    }

    return (
        <div>
            <input key={imageInputKey}
                type="file"
                name="image"
                ref={fileInput}
                onChange={(event) => {
                    console.log(event.target.files[0]);
                    handleFileSelection(event.target.files[0]);
                }}
                style={{ display: 'none' }}
            />

            <button className="button" onClick={() => handleFileUploadClick()}>
                {
                    label ? label : "Upload Image"
                }
            </button>
        </div>
    )
}

export default ImageUploadButton;