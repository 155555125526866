import About from "./screens/About/about";
import Home from "./screens/Home/home";
import Chats from "./screens/Chats/chats";
import Dashboard from "./screens/Dashboard/dashboard";
import Itineraries from "./screens/Itinerary/itineraries";
import Votes from "./screens/Votes/votes";
import AccountManagement from "./screens/Account/account-management";
import CompleteProfile from "./screens/CompleteProfile/complete-profile";
import Profile from "./screens/Profile/profile";
import ProfileSearchResults from "./screens/Profile/profile-search-results";
import NotFound from "./screens/NotFound/not-found";
import ComponentTest from "./screens/ComponentTest";
import Terms from "./screens/Legal/terms";
import Cookies from "./screens/Legal/cookies";
import Privacy from "./screens/Legal/privacy";

const AppRoutes = [
    {
        index: true,
        path: "/",
        element: <Home />
    },
    {
        index: false,
        path: "/About",
        element: <About />
    },
    {
        index: false,
        path: "/Legal",
        element: <Terms />
    },
    {
        index: false,
        path: "/Terms",
        element: <Terms />
    },
    {
        index: false,
        path: "/Cookies",
        element: <Cookies />
    },
    {
        index: false,
        path: "/Privacy",
        element: <Privacy />
    },
    {
        index: false,
        path: "/Dashboard",
        element: <Dashboard />,
        isProtected: true
    },
    {
        index: false,
        path: "/Chat",
        element: <Chats />,
        isProtected: true
    },
    {
        index: false,
        path: "/Itineraries",
        element: <Itineraries />,
        isProtected: true
    },
    {
        index: false,
        path: "/Votes",
        element: <Votes />,
        isProtected: true
    },
    {
        index: false,
        path: "/Account",
        element: <AccountManagement />,
        isProtected: true
    },
    {
        index: false,
        path: "/CompleteProfile",
        element: <CompleteProfile />,
        isProtected: true
    },
    {
        index: false,
        path: "/Profile",
        element: <Profile />,
        isProtected: true
    },
    {
        index: false,
        path: "/Profile/Search",
        element: <ProfileSearchResults />,
        isProtected: true
    },
    {
        index: false,
        path: "/ComponentTest",
        element: <ComponentTest />,
        isProtected: true
    },
    {
        index: false,
        path: "/*",
        element: <NotFound />
    }
];

export default AppRoutes;
