import React from 'react'
import './style.css';

const ThumbUpIcon = (props) => {
    const className = props.className ?? "small";

    return (
        <svg viewBox="0 0 17 17" className={className}
            onClick={() => props.onClick && props.onClick()}>
            <path d="M15.368 9.725c0.083 0.203 0.132 0.466 0.132 0.807 0 0.526-0.24 0.971-0.621 1.233 0.138 0.234 0.204 0.501 0.204 0.768 0 0.562-0.313 1.041-0.785 1.288 0.080 0.188 0.127 0.42 0.129 0.705 0.002 0.449-0.129 0.81-0.391 1.073-0.276 0.277-0.667 0.418-1.161 0.418h-5.625c-0.754 0-1.273-0.404-1.653-0.7-0.219-0.17-0.426-0.332-0.57-0.332h-2.102c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h2.102c0.487 0 0.857 0.288 1.184 0.542 0.323 0.251 0.628 0.489 1.039 0.489h5.625c0.212 0 0.373-0.043 0.452-0.123 0.068-0.068 0.102-0.19 0.101-0.362-0.003-0.437-0.092-0.531-1.027-0.531h-0.106c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1.258c0.307 0 0.53-0.197 0.53-0.468 0-0.45-0.449-0.517-0.717-0.517h-0.75c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h1.437c0.331 0 0.448-0.25 0.448-0.484 0-0.492 0-0.492-0.439-0.505-0.051-0.001-0.104-0.005-0.156-0.007h-1.076c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h0.982c0.007 0 0.013-0.004 0.020-0.004h0.631c0.295 0 0.536-0.227 0.536-0.505s-0.24-0.505-0.536-0.505h-4.453c-0.149 0-0.29-0.066-0.385-0.181-0.095-0.115-0.134-0.266-0.106-0.412l0.52-2.734c0.062-0.273 0.238-1.047 0.033-1.39-0.053-0.089-0.182-0.184-0.34-0.184 0 0 0 0 0 0-0.196 0-0.397 0.157-0.566 0.44l-3.241 5.234c-0.091 0.147-0.252 0.237-0.425 0.237h-2.569c-0.276 0-0.5-0.224-0.5-0.5s0.224-0.5 0.5-0.5h2.291l3.088-4.99c0.476-0.8 1.095-0.921 1.42-0.921 0 0 0 0 0.001 0 0.485 0 0.955 0.264 1.198 0.671 0.364 0.609 0.238 1.445 0.087 2.106l-0.403 2.124h3.849c0.847 0 1.536 0.675 1.536 1.505 0.001 0.498-0.248 0.941-0.63 1.215z" fill="#388000ff" />
        </svg>
    )
}

export default ThumbUpIcon