import React, { useState } from 'react';

const PageControl = (props) => {
    const [activePage, setActivePage] = useState(0);

    return (
        <>
            {
                props.pages[activePage]?.content
            }

            <div style={{ display: "flex" }, { flexDirection: "row" }}>
                {
                    (activePage > 0 || props.pages[activePage].backAction) &&
                    <button className="button"
                        onClick={() => props.pages[activePage].backAction
                                    ? props.pages[activePage].backAction()
                                    : setActivePage(activePage - 1)}
                        style={{ margin: "5px" }}>
                        {
                            props.pages[activePage].backText ?? "Back"
                        }
                    </button>
                }

                {
                    activePage + 1 < props.pages.length &&
                    <button className="button"
                        onClick={() => props.pages[activePage].canContinue && setActivePage(activePage + 1)}
                        style={{ margin: "5px" }}
                        disabled={!props.pages[activePage].canContinue}>
                        {
                            props.pages[activePage].nextText ?? "Next"
                        }
                    </button>
                }

                {
                    activePage + 1 === props.pages.length &&
                    <button className="button"
                        onClick={() => props.pages[activePage].canContinue && props.submitAction()}
                        style={{ margin: "5px" }}
                        disabled={!props.pages[activePage].canContinue}>
                        {
                            props.submitText ?? "Submit"
                        }
                    </button>
                }

            </div>
        </>
    )
}

export default PageControl;
