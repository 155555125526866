import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";

import MainLayout from '../../components/MainLayout/main-layout.js';
import Loading from '../../components/Loading/loading.js';
import ItineraryList from '../../components/Itinerary/itinerary-list.js';
import ItineraryCalendar from '../../components/Itinerary/itinerary-calendar.js';

import './style.css'

import { getAccount } from '../../data/account-data-access.js';
import { getItinerariesByAccountId } from '../../data/itinerary-data-access';
import ItineraryListControls from '../../components/Itinerary/itinerary-list-controls.js';

const Itineraries = (props) => {
    const { user, isAuthenticated, isAuthLoading } = useAuth0();
    const [searchParams, setSearchParams] = useSearchParams();
    const [itineraries, setItineraries] = useState(null);
    const [selectedItinerary, setSelectedItinerary] = useState();
    const [account, setAccount] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const load = async () => {
            var loadedAccount = await getUser();

            if (!loadedAccount)
                return;

            await loadItineraries(loadedAccount.Id);
        }

        load();
    }, [user]);

    useEffect(() => {
        if (itineraries)
            setIsLoading(false);

    }, [itineraries])

    useEffect(() => {
        if (!selectedItinerary)
            return;

        setSearchParams({
            itinerary: selectedItinerary.Id
        })
    }, [selectedItinerary])

    const loadItineraries = async (accountId) => {
        var res = await getItinerariesByAccountId(accountId);
        setItineraries(res);

        if (searchParams) {
            var itineraryIdFromUrl = searchParams.get("itinerary");
            var idx = res.findIndex((itinerary) => itinerary.Id == itineraryIdFromUrl);
            console.log(idx)
            if (res.length < 1)
                return;

            if (idx === -1) {
                setSelectedItinerary(res[0]);
                return;
            }

            setSelectedItinerary(res[idx]);
        }
    }

    const getUser = async () => {
        if (!isAuthenticated || isAuthLoading)
            return;

        var res = await getAccount(user.email);

        setAccount(res);

        return res;
    }

    const itineraryBody = () => {
        if (isLoading) {
            return (
                <Loading />
            )
        }
        else if (!selectedItinerary)
        {
            //Should probably show like a suggest trip button here
            return (
                <span>Looks like you haven't made any itineraries yet, suggest a new trip to your friends to get started!</span>
            )
        }
        else {
            return (
                <div className="itineraries-container3">
                    <ItineraryCalendar itinerary={selectedItinerary}
                                       account={account}
                                       loadItineraries={loadItineraries} />
                </div>
            )
        }
    }

    return (
        <MainLayout itinerariesSelected={true}
                    subMenuControls={() => (<ItineraryListControls account={account} loadItineraries={loadItineraries} />)}
                    subMenuContent={(setMobileMenuOpen) => (<ItineraryList itineraries={itineraries}
                                                                          setSelectedItinerary={setSelectedItinerary}
                                                                          selectedItinerary={selectedItinerary}
                                                                          setMobileMenuOpen={setMobileMenuOpen}/>)}>
            {
                itineraryBody()
            }
        </MainLayout>
    )
}

export default Itineraries
