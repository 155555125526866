import React from 'react'
import './style.css';

const ThumbDownIcon = (props) => {
    const className = props.className ?? "small";

    return (
        <svg viewBox="0 0 17 17" className={className}
            onClick={() => props.onClick && props.onClick()}>
            <path d="M14.568 9.501c0 0.276-0.224 0.5-0.5 0.5h-2.291l-3.088 4.99c-0.477 0.8-1.095 0.921-1.42 0.921 0 0-0.001 0-0.001 0-0.485 0-0.955-0.264-1.199-0.671-0.364-0.61-0.238-1.446-0.087-2.106l0.403-2.124h-3.849c-0.847 0-1.535-0.675-1.535-1.505 0-0.548 0.3-1.029 0.748-1.292-0.153-0.209-0.25-0.455-0.25-0.729 0-0.526 0.24-0.971 0.621-1.233-0.138-0.235-0.204-0.502-0.204-0.769 0-0.575 0.328-1.062 0.817-1.305-0.133-0.256-0.162-0.516-0.162-0.69 0-0.932 0.581-1.488 1.553-1.488h5.625c0.754 0 1.274 0.404 1.653 0.7 0.219 0.17 0.426 0.332 0.569 0.332h2.103c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5h-2.103c-0.487 0-0.857-0.288-1.184-0.542-0.322-0.253-0.627-0.49-1.038-0.49h-5.625c-0.467 0-0.553 0.165-0.553 0.488 0 0.158 0 0.527 1.027 0.527h0.105c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5h-1.258c-0.307 0-0.529 0.197-0.529 0.468 0 0.45 0.449 0.517 0.717 0.517h0.75c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5h-1.436c-0.33 0-0.448 0.25-0.448 0.484 0 0.168 0.366 0.457 0.705 0.513h0.966c0.276 0 0.5 0.224 0.5 0.5s-0.224 0.5-0.5 0.5h-0.983c-0.007 0-0.013 0.004-0.020 0.004h-0.631c-0.295 0-0.535 0.227-0.535 0.505s0.24 0.505 0.535 0.505h4.453c0.149 0 0.29 0.066 0.385 0.181 0.095 0.115 0.134 0.266 0.106 0.412l-0.52 2.734c-0.062 0.273-0.238 1.046-0.033 1.39 0.053 0.088 0.182 0.184 0.34 0.184 0.196 0 0.397-0.157 0.566-0.44l3.239-5.234c0.091-0.147 0.252-0.237 0.425-0.237h2.569c0.279 0 0.502 0.224 0.502 0.5z" fill="#E14747" />
        </svg>
    )
}

export default ThumbDownIcon