
import React, { useState, useEffect, useRef } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

import './style.css';

import { getAccount, updateAccount } from '../../data/account-data-access.js';

import Loading from '../../components/Loading/loading.js';
import Base64Image, { getBase64 } from '../../components/Utility/base-64-image';
import Popup from '../../components/Popup/popup.js';
import EditIcon from '../../components/Icons/edit.js';

import AccountManagementProperty from './account-management-property.js';
import AccountFriendList from '../Friend/account-friend-list';


//We need to do something in here (or have the backend do it) that verifies the email they're using is not already in use
const AccountManagement = (props) => {
    const { user, isAuthenticated, isAuthLoading } = useAuth0();
    const navigate = useNavigate();
    const [account, setAccount] = useState();
    const [userName, setUserName] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [bio, setBio] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const load = async () => {
            var loadedAccount = await getUser();

            if (!loadedAccount)
                return;

            setIsLoading(false);

            //need to show a 404 message here
        }

        load();
    }, [user]);

    const getUser = async () => {
        if (!isAuthenticated || isAuthLoading)
            return;

        var res = await getAccount(user.email);
        setUserName(res.UserName);
        setFirstName(res.FirstName);
        setLastName(res.LastName);
        setBio(res.Bio);
        setPhone(res.Phone);
        setEmail(res.Email);
        setAccount(res);

        return res;
    }

    const update = async () => {
        if (!account)
            return;

        var updated = {
            ...account,
            UserName: userName,
            FirstName: firstName,
            LastName: lastName,
            Bio: bio,
            Phone: phone,
            Email: email
        }

        var res = await updateAccount(updated);

        //need to handle success/failure message here
        if (!res)
            return false;

        //if successful, reload
        await getUser();
    }

    const updateProfileImage = async (image) => {
        if (!account || !image)
            return;

        var updated = {
            ...account,
            ProfileImage: image
        }

        var res = await updateAccount(updated);

        //need to handle success/failure message here
        if (!res)
            return false;

        //if successful, reload
        await getUser();
    }

    if (isLoading) {
        return (<Loading />)
    }
    else {
        return (
            <div className="account-management-body">
                <div className="account-management-container">
                    <div className="account-management-header">
                        <ProfileImage profile={account} update={(image) => updateProfileImage(image)} user={user} />
                    </div>
                    <h3 className="account-management-section-title">About You</h3>
                    <AccountManagementProperty label="User Name"
                        isRequired={true}
                        property={userName}
                        originalValue={account.UserName}
                        setProperty={setUserName}
                        update={() => update()} />

                    <AccountManagementProperty label="First Name"
                        isRequired={true}
                        property={firstName}
                        originalValue={account.FirstName}
                        setProperty={setFirstName}
                        update={() => update()} />

                    <AccountManagementProperty label="Last Name"
                        isRequired={true}
                        property={lastName}
                        originalValue={account.LastName}
                        setProperty={setLastName}
                        update={() => update()} />

                    <AccountManagementProperty label="Bio"
                        property={bio}
                        originalValue={account.Bio}
                        setProperty={setBio}
                        update={() => update()} />

                    <h3 className="account-management-section-title">Contact</h3>
                    <AccountManagementProperty label="Phone"
                        isRequired={true}
                        property={phone}
                        originalValue={account.Phone}
                        setProperty={setPhone}
                        update={() => update()} />

                    <AccountManagementProperty label="Email"
                        isRequired={true}
                        property={email}
                        originalValue={account.Email}
                        setProperty={setEmail}
                        update={() => update()} />
                </div>
                <div className="account-management-info">
                    <AccountFriendList accountId={account.Id}/>
                </div>
            </div>

        )
    }
}

const ProfileImage = (props) => {
    const [editProfileImage, setEditProfileImage] = useState(false);

    return (
        <div className="account-management-profile-image-container">
            {
                props.profile.ProfileImage
                    ? <Base64Image
                        alt={""}
                        image={props.profile.ProfileImage.Content}
                        contentType={props.profile.ProfileImage.ContentType}
                        className="account-management-profile-image"
                    />
                    : <img src={props.user.picture}
                        alt={props.user.name}
                        className="account-icon" />
            }
            <Popup trigger={editProfileImage}
                setTrigger={setEditProfileImage}
                className="vote-list-add"
                contentClassName="add-new-vote-popup-content"
                showCloseButton={true}>
                <ProfileImagePopup setProfileImage={props.setProfileImage} setEditProfileImage={setEditProfileImage} update={props.update} />
            </Popup>
            <button className="account-management-profile-image-edit-icon-container button"
                    onClick={() => setEditProfileImage(true)}>
                <span> Change Profile Pic </span>
                <EditIcon className="account-management-profile-image-edit-icon extra-small-2" />
            </button>
        </div>
    )
}

const ProfileImagePopup = (props) => {
    const [selectedImage, setSelectedImage] = useState()
    const fileInput = useRef(null);

    const handleClick = event => {
        fileInput.current.click();
    };

    const setProfileImage = async () => {
        let dataUrl = await getBase64(selectedImage);

        let image = dataUrl.replace('data:', '').replace(/^.+,/, '');
        let contentType = new RegExp("(?:image\/).*(?=\;)").exec(dataUrl)[0]
        await props.update({
            FileName: crypto.randomUUID(),
            CreatedBy: props.accountId,
            Content: image,
            ContentType: contentType
        })
        props.setEditProfileImage(false);
    }

    return (
        <div className="change-profile-image-popup">
            <input
                type="file"
                name="image"
                ref={fileInput}
                onChange={(event) => {
                    console.log(event.target.files[0]);
                    setSelectedImage(event.target.files[0]);
                }}
                style={{display: 'none'}}
            />
            <button className="account-management-edit-profile-image-button button" onClick={handleClick}>Upload New File</button>

            {
                selectedImage &&
                <p> { selectedImage.name } </p>
            }

            <button className="account-management-edit-profile-image-button button" onClick={setProfileImage}>Save</button>
        </div>
    )
}

export default AccountManagement;
