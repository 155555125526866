import React, { useState, Component } from 'react';
import NotificationIconButton from '../components/Notifications/notification-icon-button';

const ComponentTest = (props) => {

    return (
        <div>
            <NotificationIconButton></NotificationIconButton>
        </div>
    );
}



//const ComponentTest = (props) => {
//    const [loadedFlights, setLoadedFlights] = useState([]);
//    const [origin, setOrigin] = useState();
//    const [destination, setDestination] = useState();

//    const [loading, setLoading] = useState(false);

//    return (
//        <div>
//            <SuggestedLocationSearchBar originalSearch={origin} placeholder="Origin" setLocation={(item) => setOrigin(item)} />
//            <SuggestedLocationSearchBar originalSearch={destination} placeholder="Destination" setLocation={(item) => setDestination(item)} />
//            <FlightSearchController setLoadedFlights={setLoadedFlights} setLoading={setLoading} origin={origin} des/>
//            <hr />
//            <FlightSearchResults loadedFlights={loadedFlights} loading={loading}/>
//        </div>
//    )
//}

//const FlightSearchController = (props) => {
//    const [origin, setOrigin] = useState();
//    const [destination, setDestination] = useState();

//    const [maxPrice, setMaxPrice] = useState(null);

//    const [departureDate, setDepartureDate] = useState(new Date());
//    const [returnDate, setReturnDate] = useState(null);

//    const [adults, setAdults] = useState(1);
//    const [children, setChildren] = useState(0);
//    const [infants, setInfants] = useState(0);
//    const [travelClass, setTravelClass] = useState(null);
//    const [nonStop, setNonStop] = useState(true);

//    const get = () => {
//        props.setLoading(true)

//        const request = {
//            OriginLocationCode: props.origin.IATACode,
//            DestinationLocationCode: props.destination.IATACode,
//            DepartureDate: departureDate,
//            ReturnDate: returnDate,
//            Adults: adults,
//            Children: children,
//            Infants: infants,
//            TravelClass: travelClass,
//            NonStop: nonStop,
//            MaxPrice: maxPrice
//        }

//        console.log(request);

//        axios.put("api/flight/search", request)
//            .then(res => {
//                console.log(res);
//                props.setLoading(false);
//                props.setLoadedFlights(res.data);
//            })
//    }

//    return (
//        <div className="search-controller-container">
//            <hr />
//            <label>
//                Number of Adults:
//                <input type="number"
//                    value={adults}
//                    onChange={e => setAdults(e.target.valueAsNumber)} />
//            </label>
//            <hr />
//            <label>
//                Departure Date:
//                <input type="date"
//                    value={departureDate}
//                    onChange={e => setDepartureDate(e.target.value)} />
//            </label>
//            <label>
//                Return Date:
//                <input type="date"
//                    value={returnDate ?? new Date()}
//                    onChange={e => setReturnDate(e.target.value)} />
//            </label>
//            <hr />
//            <label>
//                Max Price:
//                <input type="number"
//                    value={maxPrice ?? ""}
//                    onChange={e => setMaxPrice(e.target.valueAsNumber)} />
//            </label>
//            <hr />
//            <label>
//                Travel Class:
//                <input type="text"
//                    value={travelClass ?? ""}
//                    onChange={e => setTravelClass(e.target.checked)} />
//            </label>

//            <label>
//                NonStop Flights:
//                <input type="checkbox"
//                    value={nonStop}
//                    onChange={e => setNonStop(e.target.checked)} />
//            </label>
//            <hr />
//            <button type="button"
//                className="search-button"
//                onClick={get}>
//                <span className="search-button-text-container">
//                    <span className="search-button-text">Search</span>
//                </span>
//            </button>
//        </div>
//    );
//}

//const DetailedFlightSearchController = (props) => {
//    const [origin, setOrigin] = useState();
//    const [destination, setDestination] = useState();

//    const [maxPrice, setMaxPrice] = useState('');

//    const [seatsNeeded, setSeatsNeeded] = useState(1);
//    const [departureDate, setDepartureDate] = useState(new Date());
//    const [oneWayFlights, setOneWayFlights] = useState(false);
//    const [checkedBags, setCheckedBags] = useState(true);
//    const [refundableFare, setRefundableFare] = useState(true);
//    const [noPenaltyFare, setNoPenaltyFare] = useState(true);
//    const [returnToOrigin, setReturnToOrigin] = useState(true);
//    const [flexibleDates, setFlexibleDates] = useState(true);

//    const [loading, setLoading] = useState(false);

//    const [loadedFlights, setLoadedFlights] = useState([]);

//    const get = () => {
//        setLoading(true)

//        const request = {
//            Origin: origin.IATACode,
//            Destination: destination.IATACode,
//            SeatsNeeded: seatsNeeded,
//            DepartureDate: departureDate,
//            AddOneWayFlights: oneWayFlights,
//            IncludedCheckedBags: checkedBags,
//            RefundableFare: refundableFare,
//            NoPenaltyFare: noPenaltyFare,
//            ReturnToDepartureAirport: returnToOrigin,
//            FlexibleDates: flexibleDates
//        }

//        console.log(request);

//        axios.put("api/flight/search", request)
//            .then(res => {
//                console.log(res);
//                setLoading(false);
//                setLoadedFlights(res.data);
//            })
//    }

//    return (
//        <div className="search-controller-container">
//            <SuggestedLocationSearchBar originalSearch={origin} placeholder="Origin" setLocation={(item) => setOrigin(item)} />
//            <SuggestedLocationSearchBar originalSearch={destination} placeholder="Destination" setLocation={(item) => setDestination(item)} />

//            <hr />
//            <label>
//                Number of Travellers:
//                <input type="number"
//                    value={seatsNeeded}
//                    onChange={e => setSeatsNeeded(e.target.valueAsNumber)} />
//            </label>
//            <label>
//                Departure Date:
//                <input type="date"
//                    value={departureDate}
//                    onChange={e => setDepartureDate(e.target.value)} />
//            </label>
//            <hr />
//            <label>
//                Max Price:
//                <input type="number"
//                    value={maxPrice}
//                    onChange={e => setMaxPrice(e.target.valueAsNumber)} />
//            </label>
//            <hr />
//            <label>
//                One Way Flights:
//                <input type="checkbox"
//                    value={oneWayFlights}
//                    onChange={e => setOneWayFlights(e.target.checked)} />
//            </label>
//            <label>
//                Checked Bags Included:
//                <input type="checkbox"
//                    value={checkedBags}
//                    onChange={e => setCheckedBags(e.target.checked)}/>
//            </label>
//            <label>
//                Refundable:
//                <input type="checkbox"
//                    value={refundableFare}
//                    onChange={e => setRefundableFare(e.target.checked)} />
//            </label>
//            <label>
//                No Penalty:
//                <input type="checkbox"
//                    value={noPenaltyFare}
//                    onChange={e => setNoPenaltyFare(e.target.checked)} />
//            </label>
//            <label>
//                Return To Origin:
//                <input type="checkbox"
//                    value={returnToOrigin}
//                    onChange={e => setReturnToOrigin(e.target.checked)} />
//            </label>
//            <label>
//                Flexible Dates:
//                <input type="checkbox"
//                    value={flexibleDates}
//                    onChange={e => setFlexibleDates(e.target.checked)} />
//            </label>
//            <button type="button"
//                    className="search-button"
//                    onClick={get}>
//                <span className="search-button-text-container">
//                    <span className="search-button-text">Search</span>
//                </span>
//            </button>
//        </div>
//    );
//}

//const FlightSearchResultsModel = (props) => {
//    if (props.loading || (props.loadedFlights?.length ?? 0) === 0)
//        return (<div className="search-results-container"></div>);

//    return (
//        <div className="search-results-container">
//            {
//                props.loadedFlights.map(
//                    (item, index) => (
//                        <div className="search-result-item">
//                            {
//                                item.Itineraries.map(
//                                    (itinerary, itineraryIndex) => (
//                                        <div className="flight-itinerary">
//                                            <p>Flight {itineraryIndex}</p>
//                                            <br />
//                                                {
//                                                    itinerary.Segments.map(
//                                                        (segment, segmentIndex) => (
//                                                            <div>
//                                                                <p>Carrier {segment.CarrierCode}</p>
//                                                                <p>{segment.Departure.AirportCode}</p>
//                                                                <p>{segment.Departure.At}</p>
//                                                                <p>To</p>
//                                                                <p>{segment.Arrival.AirportCode}</p>
//                                                                <p>{segment.Arrival.At}</p>
//                                                            </div>
//                                                        )
//                                                    )
//                                                }
//                                            <p>Duration {itinerary.Duration}</p>
//                                        </div>
//                                    )
//                                )
//                            }

//                            <p>Last Ticketing Date {item.LastTicketingDate}</p>
//                            <p>Seats Remaining {item.NumberOfBookableSeats}</p>
//                            <p>Price {item.Price.GrandTotal}</p>
//                        </div>
//                    )
//                )
//            }
//        </div>
//    );
//}

export default ComponentTest
