import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import moment from 'moment'
import SingleLineTextInput from '../Controls/single-line-text-input';
import ImageUploadButton from '../Controls/image-upload-button';
import { createTrip } from '../../data/itinerary-data-access';
import SuggestEventControl from './suggest-event';
import PageControl from '../Controls/page-control';
import Base64Image from '../Utility/base-64-image';

//this needs to be adapted so that the channel param is optional
const CreateTripControl = ({ channel, accountId, refresh }) => {
    const [newTripName, setNewTripName] = useState("");
    const [newGroupName, setNewGroupName] = useState("");
    const [tripDescription, setTripDescription] = useState("");
    const [selectedImage, setSelectedImage] = useState()

    const [createNewEventActive, setCreateNewEventActive] = useState(false);

    const navigate = useNavigate();

    const _createTrip = async () => {
        var request = {
            ItineraryName: newTripName.length > 0 ? newTripName : null,
            GroupName: newGroupName.length > 0 ? newGroupName : null,
            CreatedByAccountId: accountId,
            GroupId: channel.GroupId,
            TripDescription: tripDescription,
            TripSuggestionImage: selectedImage,
            MainChannelId: channel.Id
        }

        var res = await createTrip(request)

        if (!res) {
            console.log("failed to create trip")
            return;
        }

        refresh();
    }

    const goToItinerary = () => {
        navigate(`/Itineraries?itinerary=${channel?.ItineraryId}`)
    }

    var pages = [
        NameTripPage(newTripName, setNewTripName),
        //we need to do something here to ask if the user would like to create a vote for events that overlap
        DetailsPage(tripDescription, setTripDescription, selectedImage, setSelectedImage, accountId)
    ];

    if (!channel?.GroupId) {
        pages = [
            GroupPage(newGroupName, setNewGroupName),
            ...pages
        ]
    }

    if (channel?.ItineraryId) {
        if (createNewEventActive) {
            return <SuggestEventControl itineraryId={channel.ItineraryId}
                                        accountId={accountId}
                                        refresh={refresh}/>
        }

        return (
            <div className="messages-panel-popup">
                <button className="button" onClick={() => goToItinerary()}> View Trip </button>

                <button className="button" onClick={() => setCreateNewEventActive(true)}> Suggest a new event </button>
            </div>
        )
    }

    return (
        <div className="messages-panel-popup">
            <PageControl submitText="Create"
                submitAction={() => _createTrip()}
                pages={pages} />
        </div>
    )
}

const GroupPage = (newGroupName, setNewGroupName) => {
    return {
        canContinue: newGroupName.length > 0,
        content: <>
            <p>Create a trip:</p>
            <span>First, create a new group:</span>
            <SingleLineTextInput value={newGroupName}
                setValue={setNewGroupName}
                placeholder="Group Name..." />
        </>
    }
}

const NameTripPage = (newTripName, setNewTripName) => {
    return {
        canContinue: newTripName.length > 0,
        content: <>
            <p>Create a trip:</p>
            <span>Name your trip:</span>
            <SingleLineTextInput value={newTripName}
                setValue={setNewTripName}
                placeholder="Trip Name..." />
        </>
    }
}

const DetailsPage = (tripDescription, setTripDescription, selectedImage, setSelectedImage, accountId) => {
    const photoLabel = selectedImage ? "Change Photo" : "Attach a photo";

    return {
        canContinue: tripDescription.length > 0,
        content: <>
            <p>Suggest a trip:</p>
            <span>Describe your vision:</span>

            <SingleLineTextInput value={tripDescription}
                setValue={setTripDescription}
                placeholder="Write your pitch here..." />

            {
                selectedImage &&
                <Base64Image
                    alt={""}
                    image={selectedImage.Content}
                    contentType={selectedImage.ContentType}
                    className="chat-added-image"
                />
            }

            <ImageUploadButton buttonLabel={photoLabel}
                accountId={accountId}
                setSelectedImage={setSelectedImage} />
        </>
    }
}

export default CreateTripControl;