import React, { useEffect, useState } from 'react';

import Channel from './channel.js';
import './style.css'

//We need to have some sort of loading thing here, passed in as prop
const ChannelList = (props) => {
    const [channels, setChannels] = useState(props.channels);

    var groupChannels = channels?.filter((channel) => channel.TripChannels?.length > 0)
    var otherChannels = channels?.filter((channel) => !channel.TripChannels)

    useEffect(() => {
        setChannels(props.channels);
    }, [props.channels]);

    useEffect(() => {
        if (props.selectedChannel || !props.channels || props.channels.length === 0)
            return;

        props.setSelectedChannel(props.channels[0])
    })

    const selectChannel = (channel) => {
        props.setSelectedChannel(channel)

        props.setMobileMenuOpen &&
        props.setMobileMenuOpen(false);
    }

    var hasChannels = channels && channels.length > 0;

    return (
        <div className="chat-group-focused-channels-container">
            <div className="chat-group-focused-channels">
                {
                    !hasChannels &&
                    <span className="no-content-message">There are no channels to show</span>
                }
                {
                    groupChannels && groupChannels?.length > 0 &&
                    <>
                        <span className="chat-type-separator">Groups</span>
                        {
                            groupChannels.map((c, idx) => <Channel key={idx}
                                channel={c}
                                selectedChannel={props.selectedChannel}
                                setSelectedChannel={(channel) => selectChannel(channel)} />)
                        }
                    </>
                }
                {
                    otherChannels && otherChannels?.length > 0 &&
                    <>
                        <span className="chat-type-separator">Chats</span>
                        {
                            otherChannels.map((c, idx) => <Channel key={idx}
                                channel={c}
                                selectedChannel={props.selectedChannel}
                                setSelectedChannel={(channel) => selectChannel(channel)} />)
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default ChannelList

