import React from 'react'

import Logo from '../Logo/logo'
import { NavLink } from "react-router-dom";

import InstagramIcon from '../Icons/instagram.js';

import './style.css'

const Footer = (props) => {
    return (
        <footer className={`footer-footer`}>
            <div className="footer-separator"></div>
            <div className="footer-container">
                <Logo rootClassName="logo-root-class-name1" className=""></Logo>
                <NavLink className="legal-nav-button" to="/legal" > Legal </NavLink>
            </div>
            <div className="footer-container1">
                <span className="footer-text">© 2024 Twiyo inc., All Rights Reserved.</span>
                <div className="footer-icon-group">
                    <a href="https://www.instagram.com/twiyoapp/">
                        <InstagramIcon className="footer-icon" />
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer
