import React, { useState } from 'react';

import FilterIcon from '../Icons/filter.js';
import AddChatIcon from '../Icons/add-chat.js';

import './style.css'
import Popup from '../Popup/popup.js';
import PageControl from '../Controls/page-control.js';
import FindUserControl from '../Account/find-user-control.js';
import RoundedAttributeContainer from '../Controls/rounded-attribute-container.js';
import { createChannel } from '../../data/channel-data-access.js';

const ChannelListControls = (props) => {
    return (
        <div className="chat-group-focused-channel-list-controls">
            <AddChatContol account={props.account}
                           refresh={props.refresh}/>
        </div>
    )
}

const AddChatContol = (props) => {
    const [popupOpen, setPopupOpen] = useState(false);
    const [chatName, setChatName] = useState("");
    const [chatMembers, setChatMembers] = useState([]);

    const addChatMember = (account) => {
        if (chatMembers.find((member) => member.Id === account.Id
            || member.Email === account.Email)) {
            return "This user has already been added.";
        }

        setChatMembers([...chatMembers, account])

        return "";
    }

    const removeChatMember = (account) => {
        setChatMembers(chatMembers.filter((member) => member.Id !== account.Id));
    }

    const handleInput = (e, setter) => {
        if (e.target.value === '\n') {
            return;
        }

        setter(e.target.value)
    }

    const createChat = async () => {
        console.log(chatMembers);
        var members = chatMembers.map((member) => member.Id)

        var res = await createChannel(props.account.Id, null, chatName, members)

        console.log(res);
        if (res)
            setPopupOpen(false);

        props.refresh();
    }

    const pages = [
        {
            canContinue: chatName.length > 0,
            backText: "Cancel",
            backAction: () => setPopupOpen(false),
            nextText: "Continue",
            content: <>
                <p>Create a new chat:</p>
                <span>Name your chat:</span>
                <input type="text"
                    placeholder="Chat Name..."
                    className="channel-member-input"
                    value={chatName}
                    onChange={(e) => handleInput(e, setChatName)}
                />
            </>
        },
        {
            canContinue: true,
            nextText: "Continue",
            content: <>
                <p>Create a new chat:</p>
                <span>Invite your friends</span>
                <FindUserControl addMember={(account) => addChatMember(account)} />
                <div className="new-chat-members-list">
                    {
                        chatMembers && chatMembers.map((member, index) =>
                            <RoundedAttributeContainer name={member.DisplayName} remove={() => removeChatMember(member)} key={index} />
                        )
                    }
                </div>
            </>
        }
    ];

    return (
        <>
            <Popup trigger={popupOpen}
                setTrigger={setPopupOpen}
                className="itineraries-list-add"
                contentClassName="add-new-itinerary-popup-content"
                showCloseButton={true}>
                <div className="messages-panel-popup">
                    <PageControl submitText="Create"
                                 submitAction={() => createChat()}
                                 pages={pages} />
                </div>
            </Popup>
            <AddChatIcon className="small align-self-center" onClick={() => setPopupOpen(true)} />
        </>
    )
}

export default ChannelListControls

