import React, { useState, useEffect } from 'react';
import './style.css'

import ItineraryListItem from './itinerary-list-item';

//We need to have some sort of loading thing here, passed in as prop
const ItineraryList = (props) => {
    const [itineraries, setItineraries] = useState(props.itineraries);

    useEffect(() => {
        setItineraries(props.itineraries);
    }, [props.itineraries]);

    useEffect(() => {
        if (props.selectedItinerary
        || !props.itineraries || props.itineraries.length === 0)
            return;

        props.setSelectedItinerary(props.itineraries[0])
    })

    const selectItinerary = (itinerary) => {
        props.setSelectedItinerary(itinerary)

        props.setMobileMenuOpen &&
        props.setMobileMenuOpen(false);
    }

    var hasItineraries = itineraries && itineraries.length > 0;

    return (
        <div className="itineraries-list-container">
            <div className="itineraries-list-body">
                {
                    !hasItineraries &&
                    <span className="no-content-message">There are no itineraries to show</span>
                }
                {
                    hasItineraries &&
                    itineraries.map((c, idx) => <ItineraryListItem key={idx}
                        itinerary={c}
                        selectedItinerary={props.selectedItinerary}
                        setSelectedItinerary={(itinerary) => selectItinerary(itinerary)} />)
                }
            </div>
        </div>
    )
}

export default ItineraryList;