import React, { useState } from 'react';

import Navbar from '../../components/NavBar/navbar.js';
import Footer from '../../components/Footer/footer.js';
import Sidebar from '../../components/Sidebar/sidebar.js';
import AccountIcon from '../Account/account-icon.js';
import NotificationIconButton from '../Notifications/notification-icon-button.js';
import Logo from '../Logo/logo'

import useWindowDimensions from '../Utility/window-dimensions.js';

import CloseIcon from '../Icons/close.js';
import BurgerMenuIcon from '../Icons/burger-menu.js';

import './style.css'

const MainLayout = (props) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const windowDimensions = useWindowDimensions();

    const toggleDrawer = () => {
        setMobileMenuOpen(!mobileMenuOpen)

        if (mobileMenuOpen && typeof window != 'undefined' && window.document) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = '';
        }
    }

    if (windowDimensions?.isMobile ?? false) {
        return (
            <div className="main-layout-container">
                <div className="main-layout-container1">
                    <div data-role="Header" className="mobile-navbar-header">
                        <div className="mobile-navbar-container">
                            <div className="navbar-burger-menu" onClick={() => toggleDrawer()}>
                                {
                                    !mobileMenuOpen &&
                                    <BurgerMenuIcon />
                                }
                                {
                                    mobileMenuOpen &&
                                    <CloseIcon />
                                }
                            </div>

                            <Logo />

                            <div className="mobile-navbar-btn-group">
                                <NotificationIconButton />
                                <AccountIcon />
                            </div>
                        </div>


                        <div className={`mobile-sidebar-container ${mobileMenuOpen ? "mobile-sidebar-container-open" : ""}`}>
                            <Sidebar itinerariesSelected={props.itinerariesSelected}
                                     chatSelected={props.chatSelected}
                                     votesSelected={props.votesSelected}
                                     legalSelected={props.legalSelected}
                                     dashboardSelected={props.dashboardSelected}
                                     subMenuControls={props.subMenuControls}>
                                    {
                                        props.subMenuContent &&
                                        props.subMenuContent(setMobileMenuOpen)
                                    }
                            </Sidebar>
                        </div>

                    </div>
                    <div className="main-layout-container2">
                        {
                            props.children
                        }
                    </div>
                    <Footer />
                </div>
            </div>
        )
    }

    return (
        <div className="main-layout-container">
            <div className="main-layout-container1">
                <Navbar subMenuControls={props.subMenuControls}
                        subMenuContent={props.subMenuContent } />
                <div className="main-layout-container2">
                    <div className="main-sidebar-container">
                        <Sidebar itinerariesSelected={props.itinerariesSelected}
                            chatSelected={props.chatSelected}
                            votesSelected={props.votesSelected}
                            legalSelected={props.legalSelected}
                            subMenuControls={props.subMenuControls}>
                            {
                                props.subMenuContent &&
                                props.subMenuContent()
                            }
                        </Sidebar>
                    </div>
                    {
                        props.children
                    }
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default MainLayout