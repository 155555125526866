import React from 'react'
import './style.css';

const CheckIcon = (props) => {
    const className = props.className ?? "small";

    return (
        <svg viewBox="0 0 24 24" fill="none" className={className}
            onClick={() => props.onClick && props.onClick()}>
            <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default CheckIcon
