import React, { useState } from 'react'

import ArrowIcon from '../Icons/arrow.js';

import './style.css';

const SidebarCategory = (props) => {
    const [isOpen, setIsOpen] = useState(props.isOpen ?? null);

    return (
        <div>
            <button className="sidebar-category-button-container"
                onClick={() => {
                    setIsOpen(!isOpen)
                    if (props.onClick)
                        props.onClick();
                }}>

                {props.navTitle}

                <ArrowIcon className={`arrow-icon extra-small ${isOpen == null ? "" : isOpen ? "arrow-icon-open" : "arrow-icon-closed"}`} />
            </button>
            <div className="sidebar-category-content-container"> 
                {
                    isOpen &&
                    props.children
                }
            </div>
        </div>
    )
}

export default SidebarCategory