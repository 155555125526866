import React from 'react'

import ArrowIcon from '../Icons/arrow.js';

import './style.css';

const SidebarSubMenuButton = (props) => {
    return (
        <div>
            <div className="sidebar-submenu-controls">
                <button className="sidebar-submenu-navigate-back"
                    onClick={() => props.setSubMenuOpen(false)}>

                    <ArrowIcon className="arrow-icon arrow-icon-back extra-small" />

                    Back
                </button>

                {
                    props.subMenuControls &&
                    props.subMenuControls()
                }
            </div>

            {
                props.children
            }
        </div>
    )
}

export default SidebarSubMenuButton