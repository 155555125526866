import React from 'react';

import MainLayout from '../../components/MainLayout/main-layout.js'
import Loading from '../../components/Loading/loading.js';

import './style.css'

import ProfileSearchResultsComponent from '../../components/Profile/profile-search-results.js'

const ProfileSearchResults = (props) => {
    return (
        <MainLayout>
            <ProfileSearchResultsComponent />
        </MainLayout>
    )
}

export default ProfileSearchResults