import React, { useState } from 'react';

import Logo from '../Logo/logo'
import WaitlistPopup from '../Waitlist/waitlist-popup.js';
import AccountIcon from '../Account/account-icon.js';
import NotificationIconButton from '../Notifications/notification-icon-button.js';

import './style.css';

const Navbar = (props) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <div className="navbar-container">
            <header data-role="Header" className="navbar-header">
                <Logo></Logo>
                <div className="navbar-btn-group">
                    <WaitlistPopup />
                    <NotificationIconButton/>
                    <AccountIcon/>
                </div>
            </header>
        </div>
    )
}

export default Navbar
