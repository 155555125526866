import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import ArrowIcon from '../Icons/arrow.js';

import './style.css';

import { getFriendship, approveFriendRequest, rejectFriendRequest } from '../../data/friend-data-access.js';
import { updateNotification } from '../../data/notification-data-access.js';
import { getAccount, searchAccount } from '../../data/account-data-access.js';
import { getChannelMemberStatus, updateChannelMemberStatus } from "../../data/channel-data-access.js";
import { getGroupMemberStatus, updateGroupMemberStatus } from "../../data/group-data-access.js";

const friendRequestNotification = 0;
const addedToGroup = 2;
const addedToItinerary = 3;
const addedToChat = 4;
const eventAddedToItinerary = 5;
const alternativeEventSuggested = 6;
const votingEndsSoon = 7;
const itineraryStartingSoon = 8;

//Do we want to have a mark read button instead of a nav arrow or in addition to it?
const NotificationPopupItem = (props) => {
    const navigate = useNavigate();

    if (props.notification && props.notification.Type.Id === friendRequestNotification) {
        return (
            <FriendRequestNotification account={props.account}
                navigate={navigate}
                notification={props.notification}
                reloadNotifications={props.reloadNotifications} />
        )
    }

    if (props.notification && props.notification.Type.Id === addedToChat) {
        return (
            <InvitedToChannelNotification account={props.account}
                navigate={navigate}
                notification={props.notification}
                reloadNotifications={props.reloadNotifications} />
        )
    }

    if (props.notification && props.notification.Type.Id === addedToGroup) {
        return (
            <InvitedToGroupNotification account={props.account}
                navigate={navigate}
                notification={props.notification}
                reloadNotifications={props.reloadNotifications} />
        )
    }

    return (
        <DefaultNotificationItem account={props.account}
            navigate={navigate}
            notification={props.notification}
            reloadNotifications={props.reloadNotifications} />
    )
}

//need to make sure that request/accept buttons do not show up if they are already friends
const FriendRequestNotification = (props) => {
    const [friendship, setFriendship] = useState();

    useEffect(() => {
        getFriendshipStatus(props.account?.Id, props.notification?.ResourceId);
    }, [props.account]);

    const getFriendshipStatus = async (accountId, friendId) => {
        if (!accountId || !friendId || accountId === friendId)
            return;

        var res = await getFriendship(accountId, friendId);

        setFriendship(res);
    }

    const approveFriend = async () => {
        await updateNotification({
            ...props.notification,
            Read: true
        })

        await approveFriendRequest(props.account.Id, props.notification?.ResourceId)

        await props.reloadNotifications();

        await updateNotification({
            ...props.notification,
            Read: true
        })
    }

    const rejectFriend = async () => {
        await updateNotification({
            ...props.notification,
            Read: true
        })

        await rejectFriendRequest(props.account.Id, props.notification?.ResourceId)

        await props.reloadNotifications();

        await updateNotification({
            ...props.notification,
            Read: true
        })
    }

    const onItemClick = async () => {
        var user = await getAccount(null, props.notification?.ResourceId)

        if (!user)
            return;

        props.navigate(`/profile?username=${user.UserName}`)

        await updateNotification({
            ...props.notification,
            Read: true
        })
    }

    return (
        <div className="notification-popup-item"
             onClick={onItemClick}>
            <span className="notification-message">
                { props.notification?.Message }
            </span>
            <AcceptDeclineButtons acceptAction={() => approveFriend()}
                declineAction={() => rejectFriend()}
                active={friendship
                    && friendship?.Status?.Id === 0
                    && friendship?.RequestedBy !== props.account?.Id}
                accepted={friendship
                    && friendship?.Status?.Id === 1
                    && friendship?.RequestedBy !== props.account?.Id}
                declined={friendship
                    && friendship?.Status?.Id === 2
                    && friendship?.RequestedBy !== props.account?.Id}/>
        </div>
    )
}

const InvitedToChannelNotification = (props) => {
    const [channelMemberStatus, setChannelMemberStatus] = useState(-1);

    useEffect(() => {
        _getChannelMemberStatus();
    }, [props.account]);

    const _getChannelMemberStatus = async () => {
        var res = await getChannelMemberStatus(props.notification?.ResourceId, props.account.Id);
        setChannelMemberStatus(res);
    }

    const acceptChannelJoinRequest = async () => {
        await updateNotification({
            ...props.notification,
            Read: true
        })

        await updateChannelMemberStatus(props.account.Id, props.notification?.ResourceId, 1)

        await props.reloadNotifications();

        await updateNotification({
            ...props.notification,
            Read: true
        })
    }

    const rejectChannelJoinRequest = async () => {
        await updateNotification({
            ...props.notification,
            Read: true
        })

        await updateChannelMemberStatus(props.account.Id, props.notification?.ResourceId, 2)

        await props.reloadNotifications();

        await updateNotification({
            ...props.notification,
            Read: true
        })
    }

    const onItemClick = async () => {
        var user = await getAccount(null, props.notification?.ResourceId)

        if (!user)
            return;

        props.navigate(`/chat?channel=${props.notification?.ResourceId}`)

        await updateNotification({
            ...props.notification,
            Read: true
        })
    }

    return (
        <div className="notification-popup-item"
            onClick={onItemClick}>
            <span className="notification-message">
                {props.notification?.Message}
            </span>
            <AcceptDeclineButtons acceptAction={() => acceptChannelJoinRequest()}
                declineAction={() => rejectChannelJoinRequest()}
                active={channelMemberStatus === 0}
                accepted={channelMemberStatus === 1}
                declined={channelMemberStatus === 2}/>
        </div>
    )
}

const InvitedToGroupNotification = (props) => {
    const [groupMemberStatus, setGroupMemberStatus] = useState();

    useEffect(() => {
        _getGroupMemberStatus();
    }, [props.account]);

    const _getGroupMemberStatus = async () => {
        var res = await getGroupMemberStatus(props.notification?.ResourceId, props.account.Id);

        setGroupMemberStatus(res);
    }

    const acceptGroupJoinRequest = async () => {
        await updateNotification({
            ...props.notification,
            Read: true
        })

        await updateGroupMemberStatus(props.account.Id, props.notification?.ResourceId, 1)

        await props.reloadNotifications();

        await updateNotification({
            ...props.notification,
            Read: true
        })
    }

    const rejectGroupJoinRequest = async () => {
        await updateNotification({
            ...props.notification,
            Read: true
        })

        await updateGroupMemberStatus(props.account.Id, props.notification?.ResourceId, 2)

        await props.reloadNotifications();

        await updateNotification({
            ...props.notification,
            Read: true
        })
    }

    const onItemClick = async () => {
        var user = await getAccount(null, props.notification?.ResourceId)

        if (!user)
            return;

        props.navigate(`WeDontHaveAGroupPageYet=${props.notification?.ResourceId}`)

        await updateNotification({
            ...props.notification,
            Read: true
        })
    }

    return (
        <div className="notification-popup-item"
            onClick={onItemClick}>
            <span className="notification-message">
                {props.notification?.Message}
            </span>
            <AcceptDeclineButtons acceptAction={() => acceptGroupJoinRequest()}
                declineAction={() => rejectGroupJoinRequest()}
                active={groupMemberStatus === 0}
                accepted={groupMemberStatus === 1}
                declined={groupMemberStatus === 2} />
        </div>
    )
}

const DefaultNotificationItem = (props) => {

    const onItemClick = async () => {
        var resource = null;

        //This should probably be in the DB, I don't want to have to update this list for every new notification type
        switch (props.notification?.Type?.Id) {
            case itineraryStartingSoon:
            case eventAddedToItinerary:
            case addedToItinerary:
                resource = "/itineraries?itinerary="
                break;
            case votingEndsSoon:
            case alternativeEventSuggested:
                resource = "/votes?poll="
                break;
            default:
                resource = "/chat"
        }

        if (resource) {
            await updateNotification({
                ...props.notification,
                Read: true
            })

            props.navigate(`${resource}${props.notification?.ResourceId}`)
        }
    }

    return (
        <div className="notification-popup-item"
            onClick={onItemClick}>
            <span className="notification-message">
                {props.notification?.Message}
            </span>
            <div className="notification-popup-item-controls">
                <ArrowIcon className="extra-small"/>
            </div>
        </div>
    )
}

//Need to do something here to show the state of the notification i.e. was it rejected
const AcceptDeclineButtons = (props) => {
    if (props.active) {
        return (
            <div className="notification-popup-item-controls">
                <button className="popup-item-button"
                    onClick={props.acceptAction}>
                    Accept
                </button>
                <button className="popup-item-button"
                    onClick={props.declineAction}>
                    Decline
                </button>
            </div>

        )
    }

    if (props.accepted) {
        return (
            <div className="notification-popup-item-controls">
                <button className="popup-item-button"
                    disabled={true}>
                    Accepted
                </button>
            </div>
        );
    }

    if (props.declined) {
        return (
            <div className="notification-popup-item-controls">
                <button className="popup-item-button"
                    disabled={true}>
                    Declined
                </button>
            </div>
        );
    }
}

export default NotificationPopupItem;
