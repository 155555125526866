
import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";

import Loading from '../../components/Loading/loading.js';
import MainLayout from '../../components/MainLayout/main-layout.js';
import DashboardItineraryCalendar from '../../components/Itinerary/dashboard-itinerary-calendar.js'
import NotificationDashboardContent from '../../components/Notifications/notification-dashboard-content.js';
import ProfileSearchBar from '../../components/Profile/profile-search-bar.js'

import { getAccount } from '../../data/account-data-access.js';
import { getItinerariesByAccountId } from '../../data/itinerary-data-access';

import './style.css'

const Dashboard = (props) => {
    const { user, isAuthenticated, isAuthLoading } = useAuth0();
    const [itineraries, setItineraries] = useState(null);
    const [account, setAccount] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const load = async () => {
            var loadedAccount = await getUser();

            if (!loadedAccount)
                return;

            await loadItineraries(loadedAccount.Id);
        }

        load();
    }, [user]);

    useEffect(() => {
        if (itineraries)
            setIsLoading(false);

    }, [itineraries])

    const loadItineraries = async (accountId) => {
        var res = await getItinerariesByAccountId(accountId);
        setItineraries(res);
    }

    const getUser = async () => {
        if (!isAuthenticated || isAuthLoading)
            return;

        var res = await getAccount(user.email);

        setAccount(res);

        return res;
    }

    const dashboardBody = () => {
        if (isLoading) {
            return (
                <Loading />
            )
        }
        else {
            return (
                <div className="dashboard-body">
                    <ProfileSearchBar />
                    <div className="dashboard-container3">
                        <DashboardItineraryCalendar itineraries={itineraries} />
                        <NotificationDashboardContent />
                    </div>
                </div>

            )
        }
    }

    return (
        <MainLayout dashboardSelected={true} >
            {
                dashboardBody()
            }
        </MainLayout>
    )
}

export default Dashboard