import React from 'react'
import './style.css';

const FilterIcon = (props) => {
    const className = props.className ?? "small";

    return (
        <svg viewBox="0 0 1024 1024" className={className} >
            <path d="M256 554v-84h512v84h-512zM128 256h768v86h-768v-86zM426 768v-86h172v86h-172z"></path>
        </svg>
    )
}

export default FilterIcon