import React, { useState } from 'react';
import ItineraryList from './itinerary-list';
import ArrowIcon from '../Icons/arrow.js';
import './style.css'

const ItineraryListItem = (props) => {
    const isSelected = props.selectedItinerary?.Id === props.itinerary.Id;

    var itineraryName = props.itinerary?.Name ?? "New Itinerary";

    if (itineraryName > 50)
        itineraryName = itineraryName.slice(0, 50) + "...";

    var groupName = props.itinerary?.Group?.GroupName ?? "";

    if (groupName > 50)
        groupName = groupName.slice(0, 50) + "...";

    return (
        <div className="itinerary-list-item-container"
            style={{ backgroundColor: isSelected ? "var(--dl-color-gray-700)" : "unset" }}
            onClick={() => props.setSelectedItinerary(props.itinerary)}>
            <div className="itinerary-list-item-container1">
                <div className="itinerary-list-item-container2">
                    <div>
                        <h1 className="itinerary-list-item-name">
                            {itineraryName}
                        </h1>
                        <h3 className="itinerary-list-item-group-name">
                            {groupName}
                        </h3>
                    </div>

                    <ArrowIcon className="itinerary-list-item-arrow-icon"/>
                </div>

            </div>
        </div>
    )
}

export default ItineraryListItem;
