import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Popup from '../Popup/popup.js';
import NotificationPopupContent from './notification-popup-content.js';

import NotificationBellIcon from '../Icons/notification-bell.js'

import './style.css';

import { getAccount } from '../../data/account-data-access.js';
import { getAccountNotifications, updateNotification } from '../../data/notification-data-access.js';

const NotificationIconButton = () => {
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [popupOpen, setPopupOpen] = useState(false);
    const [hasNotifications, setHasNotifications] = useState(false);
    const [notifications, setNotifications] = useState(null);
    const [account, setAccount] = useState();

    document.body.style.overflow = popupOpen
                                 ? "hidden"
                                 : "unset";
    useEffect(() => {
        const load = async () => {
            var loadedAccount = await getUser();

            if (!loadedAccount)
                return;

            setNotifications(null);

            var res = await loadNotifications(loadedAccount.Id, false);

            if (res && res.length > 0)
                setHasNotifications(true);
        }

        load();
    }, [user]);

    const loadNotifications = async (accountId, read) => {
        setNotifications(null);
        var res = await getAccountNotifications(accountId, read);
        setNotifications(res);

        return res;
    }

    const getUser = async () => {
        if (!isAuthenticated || isLoading)
            return;

        var res = await getAccount(user.email);

        setAccount(res);

        return res;
    }

    if (isLoading || !isAuthenticated) {
        return;
    }

    return (
        <div>
            <button className="notification-icon-button" onClick={() => setPopupOpen(true)}>
                <NotificationBellIcon fill={hasNotifications ? "#ffc700ff" : "none"} />
            </button>
            <Popup trigger={popupOpen}
                setTrigger={setPopupOpen}
                className="notification-popup-style"
                contentClassName="notification-popup-content-style"
                showCloseButton={false}>
                <NotificationPopupContent account={account}
                                          notiifcation={notifications} />
            </Popup>
        </div>
    )
};

export default NotificationIconButton;