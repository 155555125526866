import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import SidebarButton from './sidebar-button.js';
import SidebarCategory from './sidebar-category.js';
import SidebarSubMenu from './sidebar-submenu.js';

import './style.css';

const Sidebar = (props) => {
    const navigate = useNavigate();
    const [subMenuOpen, setSubMenuOpen] = useState(false);

    useEffect(() => {
        if (!props.chatSelected
         && !props.itinerariesSelected
         && !props.votesSelected
         && !props.legalSelected)
            return;

        setSubMenuOpen(true);
    }, []);

    return (
        <div className="sidebar">
            {
                subMenuOpen &&
                <SidebarSubMenu setSubMenuOpen={setSubMenuOpen} subMenuControls={props.subMenuControls} >
                    {
                        props.children
                    }
                </SidebarSubMenu>
            }
            {
                !subMenuOpen &&
                <div>
                    <SidebarButton action={() => {
                            navigate('/Dashboard')
                        }}
                            navTitle="Dashboard"
                            isSelected={props.dashboardSelected ?? false} />
                    <SidebarButton action={() => {
                            navigate('/Chat')
                            setSubMenuOpen(true);
                        }}
                            navTitle="Chat"
                            isSelected={props.chatSelected ?? false}/>

                    <SidebarCategory navTitle="Group"
                                     isOpen={(props.itinerariesSelected ?? false)
                                          || (props.votesSelected ?? false)
                                          || (props.groupsSelected ?? false) }>
                        <SidebarButton action={() => {
                                navigate('/Itineraries')
                                setSubMenuOpen(true);
                            }}
                                navTitle="Itineraries"
                                isSelected={props.itinerariesSelected ?? false} />
                        <SidebarButton action={() => {
                                navigate('/Votes')
                                setSubMenuOpen(true);
                            }}
                                navTitle="Votes"
                                isSelected={props.votesSelected ?? false} />
                        <SidebarButton action={() => {
                                navigate('/Groups')
                                setSubMenuOpen(true);
                            }}
                                navTitle="My Groups"
                                isSelected={props.groupsSelected ?? false} />
                    </SidebarCategory>
                </div >
            }
        </div>
    )
}

export default Sidebar