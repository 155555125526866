import React, { useState } from 'react';

import Base64Image from '../Utility/base-64-image';

import './style.css'

const AgendaEvent = ({ event }) => {
    return (
        <div className="agenda-event">
            {
                event.images.map((image, id) => 
                    <Base64Image
                        alt={event.imageAltText}
                        image={image.content}
                        contentType={image.contentType }
                        className="agenda-event-image"
                    />
                )
            }
            <div className="agenda-event-body-container">
                <span className="agenda-event-title">{event.title}</span>
                <span className="agenda-event-description">{event.description}</span>
            </div>
        </div>
    )
}

export default AgendaEvent;