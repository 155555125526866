import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import './style.css';

import { getAccount, updateAccount } from '../../data/account-data-access.js';

import Loading from '../../components/Loading/loading.js';

import AccountManagementProperty from './account-management-property.js';
import NotFoundComponent from '../NotFound/not-found.js';

//we need to do something here to pass in the original path so we can navigate back to it, idk if I want to do local storage for that or what

//We need to make the continue button do the updates on this page, otherwise shit looks jank
const CompleteAccountInfo = (props) => {
    const { user, isAuthenticated, isAuthLoading } = useAuth0();
    const navigate = useNavigate();
    const [account, setAccount] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [bio, setBio] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isNotFound, setIsNotFound] = useState(false);

    useEffect(() => {
        const load = async () => {
            var loadedAccount = await getUser();

            //need to show a 404 message here
            if (!loadedAccount) {
                setIsNotFound(true)
                return;
            }

            setIsLoading(false);
        }

        load();
    }, [user]);

    const getUser = async () => {
        if (!isAuthenticated || isAuthLoading)
            return;

        var res = await getAccount(user.email);

        //if they try to get to this page and their account is already complete
        if (res?.IsAccountComplete ?? false)
            navigate('../');

        setFirstName(res.FirstName);
        setLastName(res.LastName);
        setBio(res.Bio);
        setPhone(res.Phone);
        setEmail(res.Email);
        setAccount(res);

        return res;
    }

    const update = async () => {
        if (!account)
            return;

        var updated = {
            ...account,
            FirstName: firstName,
            LastName: lastName,
            Bio: bio,
            Phone: phone,
            Email: email
        }

        var res = await updateAccount(updated);

        //need to handle success/failure message here
        if (!res)
            return false;

        //if successful, reload
        await getUser();
    }

    if (isNotFound) {
        return (<NotFoundComponent />)
    }
    else if (isLoading) {
        return (<Loading />)
    }
    else {
        return (
            <div className="complete-account-info-container">
                <h1 className="complete-account-info-header">Let's get to know each other:</h1>
                <div className="account-management-header">
                    <img src={account?.picture}
                        alt={account.Name}
                        className="account-management-profile-image" />
                    <p className="account-management-name-header">{account.Name}</p>
                </div>
                <h3 className="account-management-section-title">About You</h3>
                <AccountManagementProperty label="First Name"
                    isRequired={true}
                    property={firstName}
                    originalValue={account.FirstName}
                    setProperty={setFirstName}
                    update={() => update()} />

                <AccountManagementProperty label="Last Name"
                    isRequired={true}
                    property={lastName}
                    originalValue={account.LastName}
                    setProperty={setLastName}
                    update={() => update()} />

                <AccountManagementProperty label="Bio"
                    property={bio}
                    originalValue={account.Bio}
                    setProperty={setBio}
                    update={() => update()} />

                <h3 className="account-management-section-title">Contact</h3>
                <AccountManagementProperty label="Phone"
                    isRequired={true}
                    property={phone}
                    originalValue={account.Phone}
                    setProperty={setPhone}
                    update={() => update()} />

                {
                    !account.IsAccountComplete &&
                    <button disabled={!account.IsAccountComplete}
                        className="continue-button button"
                        onClick={() => navigate('../')}>
                        Continue
                    </button>
                }
            </div>
        )
    }
}

export default CompleteAccountInfo;
