import React from 'react';

const SingleLineTextInput = (props) => {
    const handleInput = (e, setter) => {
        if (e.target.value === '\n') {
            return;
        }

        setter(e.target.value)
    }

    return (
        <input type="text"
            placeholder={props.placeholder}
            className={props.className ?? "single-line-text-input"}
            value={props.value}
            onChange={(e) => handleInput(e, props.setValue)}
        />
    )
}

export default SingleLineTextInput