import React, { useState, useEffect } from 'react';

import { isNullOrWhitespace } from '../Utility/string-util.js'

import CheckIcon from '../Icons/check.js';
import CloseIcon from '../Icons/close.js';

import './style.css';

const AccountManagementProperty = (props) => {
    const [controlsHidden, setControlsHidden] = useState(true);
    const [originalValue, setOriginalValue] = useState("");
    const [error, setError] = useState(null);

    useEffect(() => {
        if (isNullOrWhitespace(props.originalValue) && props.isRequired) {
            setError(`${props.label} cannot be empty`)
        }

        setOriginalValue(props.originalValue);
    }, [props.originalValue]);

    const controlsStyle = {
        display: controlsHidden ? "none" : "flex"
    }

    const makeControlsVisible = () => {
        setControlsHidden(false)
    }

    const makeControlsHidden = () => {
        setControlsHidden(true);
        props.setProperty(originalValue)
    }

    const setProperty = (input) => {
        if (isNullOrWhitespace(input) && props.isRequired) {
            setError(`${props.label} cannot be empty`)
        }
        else {
            setError('')
        }

        props.setProperty(input);
    }

    const updateProperty = () => {
        if (props.property === originalValue
        || (isNullOrWhitespace(props.property) && props.isRequired))
            return;

        var res = props.update();
        if (!res) {
            props.setProperty(originalValue)
            setError(`failed to update ${props.label}`)
            return;
        }

        setOriginalValue(props.property);
        makeControlsHidden();
    }

    return (
        <div className="account-management-property-container">
            <p className="account-management-property-label">{props.label}</p>
            <div className="account-management-property-input-container">
                <input
                    type="text"
                    placeholder={props.label}
                    className="account-management-property-input"
                    onChange={(event) => {
                        setProperty(event.target.value)
                    }}
                    onFocus={(event) => {
                        makeControlsVisible()
                    }}
                    onBlur={(event) => {
                        makeControlsHidden()
                    }}
                    value={props.property ?? ""}
                />

                <div className="account-management-property-controls" style={controlsStyle}>
                    <button className="account-management-property-controls-button"
                        onMouseDown={updateProperty}>
                        <CheckIcon className="extra-small-2" />
                    </button>
                    <button className="account-management-property-controls-button"
                        onMouseDown={makeControlsHidden}>
                        <CloseIcon className="extra-small-2" />
                    </button>
                </div>
            </div>
            {
                error &&
                <label className="error-message-label">{error}</label>
            }
        </div>
    )
}

export default AccountManagementProperty