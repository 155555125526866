import React, { useState } from 'react';
import moment from 'moment'
import SingleLineTextInput from '../Controls/single-line-text-input';
import ImageUploadButton from '../Controls/image-upload-button';
import PageControl from '../Controls/page-control';
import { suggestEvent } from '../../data/itinerary-data-access';
import Base64Image from '../Utility/base-64-image';

const SuggestEventControl = ({ itineraryId, accountId, startEndDateRange, refresh }) => {
    const [newEventName, setNewEventName] = useState("");
    const [startDate, setStartDate] = useState(moment(startEndDateRange?.StartDate ?? new Date()).format("YYYY-MM-DDTHH:mm"));
    const [endDate, setEndDate] = useState(moment(startEndDateRange?.EndDate ?? new Date()).format("YYYY-MM-DDTHH:mm"));
    const [eventDescription, setEventDescription] = useState("");
    const [selectedImage, setSelectedImage] = useState()

    //need to keep a collection of attributes here, for now we will only support description
    const _suggestEvent = async () => {
        var request = {
            Name: newEventName,
            StartDate: startDate,
            EndDate: endDate,
            Attributes: [
                {
                    Key: "description",
                    Value: eventDescription
                }
            ]
        }

        request.Images = selectedImage
            ? [selectedImage]
            : [];

        var res = await suggestEvent(itineraryId, accountId, request);

        if (!res) {
            console.log("failed to suggest event")
            return;
        }

        refresh();
    }


    var pages = [
        NameEventPage(newEventName, setNewEventName),
        SetDatesPage(startDate, setStartDate, endDate, setEndDate),
        //we need to do something here to ask if the user would like to create a vote for events that overlap
        DetailsPage(eventDescription, setEventDescription, selectedImage, setSelectedImage, accountId)
    ];

    return (
        <div className="messages-panel-popup">
            <PageControl submitText="Create"
                submitAction={() => _suggestEvent()}
                pages={pages} />
        </div>
    )
}

const NameEventPage = (newEventName, setNewEventName) => {
    return {
        canContinue: newEventName.length > 0,
        content: <>
            <p>Suggest an event:</p>
            <span>Name your event:</span>
            <SingleLineTextInput value={newEventName}
                setValue={setNewEventName}
                placeholder="Event Name..." />
        </>
    }
}

const SetDatesPage = (startDate, setStartDate, endDate, setEndDate) => {
    return {
        canContinue: true,
        content: <>
            <p>Suggest an event:</p>
            <span>When will your event take place?</span>
            <label className="trip-add-event-label">
                Start Date:
            </label>

            <input
                type="datetime-local"
                className="trip-add-event-input"
                onChange={(event) => {
                    setStartDate(event.target.value)
                }}
                value={startDate}
            />


            <label className="trip-add-event-label">
                End Date:
            </label>

            <input
                type="datetime-local"
                className="trip-add-event-input"
                onChange={(event) => {
                    setEndDate(event.target.value)
                }}
                value={endDate}
            />
        </>
    }
}

const DetailsPage = (eventDescription, setEventDescription, selectedImage, setSelectedImage, accountId) => {
    const photoLabel = selectedImage ? "Change Photo" : "Attach a photo";

    return {
        canContinue: eventDescription.length > 0,
        content: <>
            <p>Suggest an event:</p>
            <span>Describe your vision:</span>

            <SingleLineTextInput value={eventDescription}
                setValue={setEventDescription}
                placeholder="Write your pitch here..." />

            {
                selectedImage &&
                <Base64Image
                    alt={""}
                    image={selectedImage.Content}
                    contentType={selectedImage.ContentType}
                    className="chat-added-image"
                />
            }

            <ImageUploadButton buttonLabel={photoLabel}
                accountId={accountId}
                setSelectedImage={setSelectedImage}/>
        </>
    }
}

export default SuggestEventControl;